// NB: This file is generated automatically. Do not edit.
export default {
  AB10: require('./district/AB10.data.json'),
  AB11: require('./district/AB11.data.json'),
  AB12: require('./district/AB12.data.json'),
  AB15: require('./district/AB15.data.json'),
  AB16: require('./district/AB16.data.json'),
  AB21: require('./district/AB21.data.json'),
  AB22: require('./district/AB22.data.json'),
  AB23: require('./district/AB23.data.json'),
  AB24: require('./district/AB24.data.json'),
  AB25: require('./district/AB25.data.json'),
  AB31: require('./district/AB31.data.json'),
  AB32: require('./district/AB32.data.json'),
  AB33: require('./district/AB33.data.json'),
  AB34: require('./district/AB34.data.json'),
  AB35: require('./district/AB35.data.json'),
  AB37: require('./district/AB37.data.json'),
  AB39: require('./district/AB39.data.json'),
  AB41: require('./district/AB41.data.json'),
  AB42: require('./district/AB42.data.json'),
  AB43: require('./district/AB43.data.json'),
  AB45: require('./district/AB45.data.json'),
  AB51: require('./district/AB51.data.json'),
  AB52: require('./district/AB52.data.json'),
  AB53: require('./district/AB53.data.json'),
  AB54: require('./district/AB54.data.json'),
  AB55: require('./district/AB55.data.json'),
  AL1: require('./district/AL1.data.json'),
  AL10: require('./district/AL10.data.json'),
  AL2: require('./district/AL2.data.json'),
  AL3: require('./district/AL3.data.json'),
  AL4: require('./district/AL4.data.json'),
  AL5: require('./district/AL5.data.json'),
  AL6: require('./district/AL6.data.json'),
  AL7: require('./district/AL7.data.json'),
  AL8: require('./district/AL8.data.json'),
  B1: require('./district/B1.data.json'),
  B10: require('./district/B10.data.json'),
  B11: require('./district/B11.data.json'),
  B12: require('./district/B12.data.json'),
  B13: require('./district/B13.data.json'),
  B14: require('./district/B14.data.json'),
  B15: require('./district/B15.data.json'),
  B16: require('./district/B16.data.json'),
  B17: require('./district/B17.data.json'),
  B18: require('./district/B18.data.json'),
  B19: require('./district/B19.data.json'),
  B20: require('./district/B20.data.json'),
  B21: require('./district/B21.data.json'),
  B23: require('./district/B23.data.json'),
  B24: require('./district/B24.data.json'),
  B25: require('./district/B25.data.json'),
  B26: require('./district/B26.data.json'),
  B27: require('./district/B27.data.json'),
  B28: require('./district/B28.data.json'),
  B29: require('./district/B29.data.json'),
  B3: require('./district/B3.data.json'),
  B30: require('./district/B30.data.json'),
  B31: require('./district/B31.data.json'),
  B32: require('./district/B32.data.json'),
  B33: require('./district/B33.data.json'),
  B36: require('./district/B36.data.json'),
  B37: require('./district/B37.data.json'),
  B38: require('./district/B38.data.json'),
  B4: require('./district/B4.data.json'),
  B42: require('./district/B42.data.json'),
  B44: require('./district/B44.data.json'),
  B45: require('./district/B45.data.json'),
  B46: require('./district/B46.data.json'),
  B49: require('./district/B49.data.json'),
  B5: require('./district/B5.data.json'),
  B50: require('./district/B50.data.json'),
  B6: require('./district/B6.data.json'),
  B60: require('./district/B60.data.json'),
  B61: require('./district/B61.data.json'),
  B62: require('./district/B62.data.json'),
  B63: require('./district/B63.data.json'),
  B64: require('./district/B64.data.json'),
  B65: require('./district/B65.data.json'),
  B66: require('./district/B66.data.json'),
  B67: require('./district/B67.data.json'),
  B68: require('./district/B68.data.json'),
  B69: require('./district/B69.data.json'),
  B7: require('./district/B7.data.json'),
  B70: require('./district/B70.data.json'),
  B71: require('./district/B71.data.json'),
  B72: require('./district/B72.data.json'),
  B74: require('./district/B74.data.json'),
  B75: require('./district/B75.data.json'),
  B76: require('./district/B76.data.json'),
  B77: require('./district/B77.data.json'),
  B78: require('./district/B78.data.json'),
  B79: require('./district/B79.data.json'),
  B8: require('./district/B8.data.json'),
  B80: require('./district/B80.data.json'),
  B9: require('./district/B9.data.json'),
  B90: require('./district/B90.data.json'),
  B91: require('./district/B91.data.json'),
  B92: require('./district/B92.data.json'),
  B93: require('./district/B93.data.json'),
  B96: require('./district/B96.data.json'),
  B97: require('./district/B97.data.json'),
  B98: require('./district/B98.data.json'),
  BA1: require('./district/BA1.data.json'),
  BA11: require('./district/BA11.data.json'),
  BA12: require('./district/BA12.data.json'),
  BA14: require('./district/BA14.data.json'),
  BA15: require('./district/BA15.data.json'),
  BA16: require('./district/BA16.data.json'),
  BA2: require('./district/BA2.data.json'),
  BA20: require('./district/BA20.data.json'),
  BA21: require('./district/BA21.data.json'),
  BA22: require('./district/BA22.data.json'),
  BA3: require('./district/BA3.data.json'),
  BA4: require('./district/BA4.data.json'),
  BA6: require('./district/BA6.data.json'),
  BA9: require('./district/BA9.data.json'),
  BB1: require('./district/BB1.data.json'),
  BB10: require('./district/BB10.data.json'),
  BB11: require('./district/BB11.data.json'),
  BB12: require('./district/BB12.data.json'),
  BB2: require('./district/BB2.data.json'),
  BB3: require('./district/BB3.data.json'),
  BB4: require('./district/BB4.data.json'),
  BB5: require('./district/BB5.data.json'),
  BB6: require('./district/BB6.data.json'),
  BB7: require('./district/BB7.data.json'),
  BB9: require('./district/BB9.data.json'),
  BD1: require('./district/BD1.data.json'),
  BD10: require('./district/BD10.data.json'),
  BD13: require('./district/BD13.data.json'),
  BD15: require('./district/BD15.data.json'),
  BD16: require('./district/BD16.data.json'),
  BD17: require('./district/BD17.data.json'),
  BD18: require('./district/BD18.data.json'),
  BD19: require('./district/BD19.data.json'),
  BD2: require('./district/BD2.data.json'),
  BD20: require('./district/BD20.data.json'),
  BD21: require('./district/BD21.data.json'),
  BD22: require('./district/BD22.data.json'),
  BD23: require('./district/BD23.data.json'),
  BD24: require('./district/BD24.data.json'),
  BD3: require('./district/BD3.data.json'),
  BD4: require('./district/BD4.data.json'),
  BD5: require('./district/BD5.data.json'),
  BD6: require('./district/BD6.data.json'),
  BD7: require('./district/BD7.data.json'),
  BD8: require('./district/BD8.data.json'),
  BD9: require('./district/BD9.data.json'),
  BH1: require('./district/BH1.data.json'),
  BH10: require('./district/BH10.data.json'),
  BH12: require('./district/BH12.data.json'),
  BH13: require('./district/BH13.data.json'),
  BH14: require('./district/BH14.data.json'),
  BH15: require('./district/BH15.data.json'),
  BH16: require('./district/BH16.data.json'),
  BH17: require('./district/BH17.data.json'),
  BH18: require('./district/BH18.data.json'),
  BH19: require('./district/BH19.data.json'),
  BH2: require('./district/BH2.data.json'),
  BH20: require('./district/BH20.data.json'),
  BH21: require('./district/BH21.data.json'),
  BH23: require('./district/BH23.data.json'),
  BH24: require('./district/BH24.data.json'),
  BH25: require('./district/BH25.data.json'),
  BH3: require('./district/BH3.data.json'),
  BH4: require('./district/BH4.data.json'),
  BH5: require('./district/BH5.data.json'),
  BH6: require('./district/BH6.data.json'),
  BH7: require('./district/BH7.data.json'),
  BH8: require('./district/BH8.data.json'),
  BH9: require('./district/BH9.data.json'),
  BL0: require('./district/BL0.data.json'),
  BL1: require('./district/BL1.data.json'),
  BL2: require('./district/BL2.data.json'),
  BL3: require('./district/BL3.data.json'),
  BL4: require('./district/BL4.data.json'),
  BL5: require('./district/BL5.data.json'),
  BL6: require('./district/BL6.data.json'),
  BL8: require('./district/BL8.data.json'),
  BL9: require('./district/BL9.data.json'),
  BN1: require('./district/BN1.data.json'),
  BN11: require('./district/BN11.data.json'),
  BN12: require('./district/BN12.data.json'),
  BN13: require('./district/BN13.data.json'),
  BN15: require('./district/BN15.data.json'),
  BN16: require('./district/BN16.data.json'),
  BN17: require('./district/BN17.data.json'),
  BN18: require('./district/BN18.data.json'),
  BN2: require('./district/BN2.data.json'),
  BN20: require('./district/BN20.data.json'),
  BN21: require('./district/BN21.data.json'),
  BN22: require('./district/BN22.data.json'),
  BN23: require('./district/BN23.data.json'),
  BN24: require('./district/BN24.data.json'),
  BN25: require('./district/BN25.data.json'),
  BN26: require('./district/BN26.data.json'),
  BN27: require('./district/BN27.data.json'),
  BN3: require('./district/BN3.data.json'),
  BN41: require('./district/BN41.data.json'),
  BN43: require('./district/BN43.data.json'),
  BN44: require('./district/BN44.data.json'),
  BN45: require('./district/BN45.data.json'),
  BN5: require('./district/BN5.data.json'),
  BN6: require('./district/BN6.data.json'),
  BN7: require('./district/BN7.data.json'),
  BN8: require('./district/BN8.data.json'),
  BN9: require('./district/BN9.data.json'),
  BR1: require('./district/BR1.data.json'),
  BR2: require('./district/BR2.data.json'),
  BR3: require('./district/BR3.data.json'),
  BR4: require('./district/BR4.data.json'),
  BR5: require('./district/BR5.data.json'),
  BR6: require('./district/BR6.data.json'),
  BR7: require('./district/BR7.data.json'),
  BS1: require('./district/BS1.data.json'),
  BS10: require('./district/BS10.data.json'),
  BS11: require('./district/BS11.data.json'),
  BS13: require('./district/BS13.data.json'),
  BS14: require('./district/BS14.data.json'),
  BS15: require('./district/BS15.data.json'),
  BS16: require('./district/BS16.data.json'),
  BS2: require('./district/BS2.data.json'),
  BS20: require('./district/BS20.data.json'),
  BS21: require('./district/BS21.data.json'),
  BS22: require('./district/BS22.data.json'),
  BS23: require('./district/BS23.data.json'),
  BS3: require('./district/BS3.data.json'),
  BS30: require('./district/BS30.data.json'),
  BS31: require('./district/BS31.data.json'),
  BS32: require('./district/BS32.data.json'),
  BS34: require('./district/BS34.data.json'),
  BS35: require('./district/BS35.data.json'),
  BS36: require('./district/BS36.data.json'),
  BS39: require('./district/BS39.data.json'),
  BS4: require('./district/BS4.data.json'),
  BS40: require('./district/BS40.data.json'),
  BS41: require('./district/BS41.data.json'),
  BS48: require('./district/BS48.data.json'),
  BS49: require('./district/BS49.data.json'),
  BS5: require('./district/BS5.data.json'),
  BS6: require('./district/BS6.data.json'),
  BS7: require('./district/BS7.data.json'),
  BS8: require('./district/BS8.data.json'),
  BS9: require('./district/BS9.data.json'),
  BS99: require('./district/BS99.data.json'),
  BT1: require('./district/BT1.data.json'),
  BT10: require('./district/BT10.data.json'),
  BT11: require('./district/BT11.data.json'),
  BT12: require('./district/BT12.data.json'),
  BT13: require('./district/BT13.data.json'),
  BT14: require('./district/BT14.data.json'),
  BT15: require('./district/BT15.data.json'),
  BT16: require('./district/BT16.data.json'),
  BT17: require('./district/BT17.data.json'),
  BT18: require('./district/BT18.data.json'),
  BT19: require('./district/BT19.data.json'),
  BT2: require('./district/BT2.data.json'),
  BT20: require('./district/BT20.data.json'),
  BT22: require('./district/BT22.data.json'),
  BT23: require('./district/BT23.data.json'),
  BT24: require('./district/BT24.data.json'),
  BT25: require('./district/BT25.data.json'),
  BT26: require('./district/BT26.data.json'),
  BT27: require('./district/BT27.data.json'),
  BT28: require('./district/BT28.data.json'),
  BT29: require('./district/BT29.data.json'),
  BT3: require('./district/BT3.data.json'),
  BT30: require('./district/BT30.data.json'),
  BT32: require('./district/BT32.data.json'),
  BT33: require('./district/BT33.data.json'),
  BT34: require('./district/BT34.data.json'),
  BT35: require('./district/BT35.data.json'),
  BT36: require('./district/BT36.data.json'),
  BT37: require('./district/BT37.data.json'),
  BT38: require('./district/BT38.data.json'),
  BT39: require('./district/BT39.data.json'),
  BT4: require('./district/BT4.data.json'),
  BT40: require('./district/BT40.data.json'),
  BT41: require('./district/BT41.data.json'),
  BT42: require('./district/BT42.data.json'),
  BT43: require('./district/BT43.data.json'),
  BT44: require('./district/BT44.data.json'),
  BT45: require('./district/BT45.data.json'),
  BT46: require('./district/BT46.data.json'),
  BT47: require('./district/BT47.data.json'),
  BT48: require('./district/BT48.data.json'),
  BT49: require('./district/BT49.data.json'),
  BT5: require('./district/BT5.data.json'),
  BT51: require('./district/BT51.data.json'),
  BT52: require('./district/BT52.data.json'),
  BT53: require('./district/BT53.data.json'),
  BT54: require('./district/BT54.data.json'),
  BT6: require('./district/BT6.data.json'),
  BT60: require('./district/BT60.data.json'),
  BT61: require('./district/BT61.data.json'),
  BT62: require('./district/BT62.data.json'),
  BT63: require('./district/BT63.data.json'),
  BT64: require('./district/BT64.data.json'),
  BT66: require('./district/BT66.data.json'),
  BT67: require('./district/BT67.data.json'),
  BT7: require('./district/BT7.data.json'),
  BT70: require('./district/BT70.data.json'),
  BT71: require('./district/BT71.data.json'),
  BT74: require('./district/BT74.data.json'),
  BT76: require('./district/BT76.data.json'),
  BT78: require('./district/BT78.data.json'),
  BT79: require('./district/BT79.data.json'),
  BT8: require('./district/BT8.data.json'),
  BT80: require('./district/BT80.data.json'),
  BT82: require('./district/BT82.data.json'),
  BT9: require('./district/BT9.data.json'),
  BT92: require('./district/BT92.data.json'),
  CA1: require('./district/CA1.data.json'),
  CA10: require('./district/CA10.data.json'),
  CA11: require('./district/CA11.data.json'),
  CA12: require('./district/CA12.data.json'),
  CA13: require('./district/CA13.data.json'),
  CA14: require('./district/CA14.data.json'),
  CA15: require('./district/CA15.data.json'),
  CA16: require('./district/CA16.data.json'),
  CA17: require('./district/CA17.data.json'),
  CA18: require('./district/CA18.data.json'),
  CA2: require('./district/CA2.data.json'),
  CA23: require('./district/CA23.data.json'),
  CA24: require('./district/CA24.data.json'),
  CA28: require('./district/CA28.data.json'),
  CA3: require('./district/CA3.data.json'),
  CA4: require('./district/CA4.data.json'),
  CA6: require('./district/CA6.data.json'),
  CA7: require('./district/CA7.data.json'),
  CA8: require('./district/CA8.data.json'),
  CA9: require('./district/CA9.data.json'),
  CB1: require('./district/CB1.data.json'),
  CB10: require('./district/CB10.data.json'),
  CB11: require('./district/CB11.data.json'),
  CB2: require('./district/CB2.data.json'),
  CB21: require('./district/CB21.data.json'),
  CB22: require('./district/CB22.data.json'),
  CB23: require('./district/CB23.data.json'),
  CB24: require('./district/CB24.data.json'),
  CB25: require('./district/CB25.data.json'),
  CB3: require('./district/CB3.data.json'),
  CB4: require('./district/CB4.data.json'),
  CB5: require('./district/CB5.data.json'),
  CB6: require('./district/CB6.data.json'),
  CB7: require('./district/CB7.data.json'),
  CB8: require('./district/CB8.data.json'),
  CB9: require('./district/CB9.data.json'),
  CF10: require('./district/CF10.data.json'),
  CF11: require('./district/CF11.data.json'),
  CF14: require('./district/CF14.data.json'),
  CF15: require('./district/CF15.data.json'),
  CF23: require('./district/CF23.data.json'),
  CF24: require('./district/CF24.data.json'),
  CF3: require('./district/CF3.data.json'),
  CF31: require('./district/CF31.data.json'),
  CF33: require('./district/CF33.data.json'),
  CF35: require('./district/CF35.data.json'),
  CF36: require('./district/CF36.data.json'),
  CF37: require('./district/CF37.data.json'),
  CF38: require('./district/CF38.data.json'),
  CF39: require('./district/CF39.data.json'),
  CF40: require('./district/CF40.data.json'),
  CF45: require('./district/CF45.data.json'),
  CF46: require('./district/CF46.data.json'),
  CF47: require('./district/CF47.data.json'),
  CF48: require('./district/CF48.data.json'),
  CF5: require('./district/CF5.data.json'),
  CF61: require('./district/CF61.data.json'),
  CF62: require('./district/CF62.data.json'),
  CF63: require('./district/CF63.data.json'),
  CF64: require('./district/CF64.data.json'),
  CF71: require('./district/CF71.data.json'),
  CF72: require('./district/CF72.data.json'),
  CF81: require('./district/CF81.data.json'),
  CF82: require('./district/CF82.data.json'),
  CF83: require('./district/CF83.data.json'),
  CH1: require('./district/CH1.data.json'),
  CH2: require('./district/CH2.data.json'),
  CH3: require('./district/CH3.data.json'),
  CH4: require('./district/CH4.data.json'),
  CH41: require('./district/CH41.data.json'),
  CH43: require('./district/CH43.data.json'),
  CH44: require('./district/CH44.data.json'),
  CH45: require('./district/CH45.data.json'),
  CH47: require('./district/CH47.data.json'),
  CH48: require('./district/CH48.data.json'),
  CH49: require('./district/CH49.data.json'),
  CH5: require('./district/CH5.data.json'),
  CH62: require('./district/CH62.data.json'),
  CH63: require('./district/CH63.data.json'),
  CH64: require('./district/CH64.data.json'),
  CH65: require('./district/CH65.data.json'),
  CH66: require('./district/CH66.data.json'),
  CH7: require('./district/CH7.data.json'),
  CH8: require('./district/CH8.data.json'),
  CM0: require('./district/CM0.data.json'),
  CM1: require('./district/CM1.data.json'),
  CM11: require('./district/CM11.data.json'),
  CM12: require('./district/CM12.data.json'),
  CM13: require('./district/CM13.data.json'),
  CM15: require('./district/CM15.data.json'),
  CM16: require('./district/CM16.data.json'),
  CM17: require('./district/CM17.data.json'),
  CM18: require('./district/CM18.data.json'),
  CM19: require('./district/CM19.data.json'),
  CM2: require('./district/CM2.data.json'),
  CM20: require('./district/CM20.data.json'),
  CM22: require('./district/CM22.data.json'),
  CM23: require('./district/CM23.data.json'),
  CM3: require('./district/CM3.data.json'),
  CM7: require('./district/CM7.data.json'),
  CM77: require('./district/CM77.data.json'),
  CM9: require('./district/CM9.data.json'),
  CO1: require('./district/CO1.data.json'),
  CO10: require('./district/CO10.data.json'),
  CO11: require('./district/CO11.data.json'),
  CO12: require('./district/CO12.data.json'),
  CO13: require('./district/CO13.data.json'),
  CO14: require('./district/CO14.data.json'),
  CO15: require('./district/CO15.data.json'),
  CO16: require('./district/CO16.data.json'),
  CO3: require('./district/CO3.data.json'),
  CO4: require('./district/CO4.data.json'),
  CO5: require('./district/CO5.data.json'),
  CO6: require('./district/CO6.data.json'),
  CO7: require('./district/CO7.data.json'),
  CO9: require('./district/CO9.data.json'),
  CR0: require('./district/CR0.data.json'),
  CR2: require('./district/CR2.data.json'),
  CR3: require('./district/CR3.data.json'),
  CR4: require('./district/CR4.data.json'),
  CR5: require('./district/CR5.data.json'),
  CR7: require('./district/CR7.data.json'),
  CR8: require('./district/CR8.data.json'),
  CR9: require('./district/CR9.data.json'),
  CT1: require('./district/CT1.data.json'),
  CT10: require('./district/CT10.data.json'),
  CT13: require('./district/CT13.data.json'),
  CT14: require('./district/CT14.data.json'),
  CT16: require('./district/CT16.data.json'),
  CT17: require('./district/CT17.data.json'),
  CT18: require('./district/CT18.data.json'),
  CT19: require('./district/CT19.data.json'),
  CT2: require('./district/CT2.data.json'),
  CT20: require('./district/CT20.data.json'),
  CT21: require('./district/CT21.data.json'),
  CT3: require('./district/CT3.data.json'),
  CT4: require('./district/CT4.data.json'),
  CT5: require('./district/CT5.data.json'),
  CT6: require('./district/CT6.data.json'),
  CT7: require('./district/CT7.data.json'),
  CT9: require('./district/CT9.data.json'),
  CV1: require('./district/CV1.data.json'),
  CV10: require('./district/CV10.data.json'),
  CV11: require('./district/CV11.data.json'),
  CV13: require('./district/CV13.data.json'),
  CV2: require('./district/CV2.data.json'),
  CV21: require('./district/CV21.data.json'),
  CV22: require('./district/CV22.data.json'),
  CV23: require('./district/CV23.data.json'),
  CV3: require('./district/CV3.data.json'),
  CV31: require('./district/CV31.data.json'),
  CV32: require('./district/CV32.data.json'),
  CV33: require('./district/CV33.data.json'),
  CV34: require('./district/CV34.data.json'),
  CV35: require('./district/CV35.data.json'),
  CV36: require('./district/CV36.data.json'),
  CV37: require('./district/CV37.data.json'),
  CV4: require('./district/CV4.data.json'),
  CV47: require('./district/CV47.data.json'),
  CV5: require('./district/CV5.data.json'),
  CV6: require('./district/CV6.data.json'),
  CV7: require('./district/CV7.data.json'),
  CV8: require('./district/CV8.data.json'),
  CV9: require('./district/CV9.data.json'),
  CW1: require('./district/CW1.data.json'),
  CW10: require('./district/CW10.data.json'),
  CW11: require('./district/CW11.data.json'),
  CW12: require('./district/CW12.data.json'),
  CW2: require('./district/CW2.data.json'),
  CW5: require('./district/CW5.data.json'),
  CW6: require('./district/CW6.data.json'),
  CW7: require('./district/CW7.data.json'),
  CW8: require('./district/CW8.data.json'),
  CW9: require('./district/CW9.data.json'),
  DA1: require('./district/DA1.data.json'),
  DA11: require('./district/DA11.data.json'),
  DA12: require('./district/DA12.data.json'),
  DA13: require('./district/DA13.data.json'),
  DA14: require('./district/DA14.data.json'),
  DA15: require('./district/DA15.data.json'),
  DA16: require('./district/DA16.data.json'),
  DA2: require('./district/DA2.data.json'),
  DA3: require('./district/DA3.data.json'),
  DA5: require('./district/DA5.data.json'),
  DA6: require('./district/DA6.data.json'),
  DA8: require('./district/DA8.data.json'),
  DD1: require('./district/DD1.data.json'),
  DD10: require('./district/DD10.data.json'),
  DD11: require('./district/DD11.data.json'),
  DD2: require('./district/DD2.data.json'),
  DD3: require('./district/DD3.data.json'),
  DD4: require('./district/DD4.data.json'),
  DD5: require('./district/DD5.data.json'),
  DD6: require('./district/DD6.data.json'),
  DD7: require('./district/DD7.data.json'),
  DD8: require('./district/DD8.data.json'),
  DD9: require('./district/DD9.data.json'),
  DE1: require('./district/DE1.data.json'),
  DE11: require('./district/DE11.data.json'),
  DE12: require('./district/DE12.data.json'),
  DE13: require('./district/DE13.data.json'),
  DE14: require('./district/DE14.data.json'),
  DE15: require('./district/DE15.data.json'),
  DE21: require('./district/DE21.data.json'),
  DE22: require('./district/DE22.data.json'),
  DE23: require('./district/DE23.data.json'),
  DE24: require('./district/DE24.data.json'),
  DE4: require('./district/DE4.data.json'),
  DE45: require('./district/DE45.data.json'),
  DE5: require('./district/DE5.data.json'),
  DE55: require('./district/DE55.data.json'),
  DE56: require('./district/DE56.data.json'),
  DE6: require('./district/DE6.data.json'),
  DE65: require('./district/DE65.data.json'),
  DE7: require('./district/DE7.data.json'),
  DE72: require('./district/DE72.data.json'),
  DE74: require('./district/DE74.data.json'),
  DE75: require('./district/DE75.data.json'),
  DG1: require('./district/DG1.data.json'),
  DG11: require('./district/DG11.data.json'),
  DG12: require('./district/DG12.data.json'),
  DG13: require('./district/DG13.data.json'),
  DG3: require('./district/DG3.data.json'),
  DG5: require('./district/DG5.data.json'),
  DG7: require('./district/DG7.data.json'),
  DG8: require('./district/DG8.data.json'),
  DH1: require('./district/DH1.data.json'),
  DH2: require('./district/DH2.data.json'),
  DH3: require('./district/DH3.data.json'),
  DH4: require('./district/DH4.data.json'),
  DH6: require('./district/DH6.data.json'),
  DH7: require('./district/DH7.data.json'),
  DH8: require('./district/DH8.data.json'),
  DH9: require('./district/DH9.data.json'),
  DL1: require('./district/DL1.data.json'),
  DL10: require('./district/DL10.data.json'),
  DL12: require('./district/DL12.data.json'),
  DL13: require('./district/DL13.data.json'),
  DL14: require('./district/DL14.data.json'),
  DL15: require('./district/DL15.data.json'),
  DL16: require('./district/DL16.data.json'),
  DL2: require('./district/DL2.data.json'),
  DL3: require('./district/DL3.data.json'),
  DL5: require('./district/DL5.data.json'),
  DL6: require('./district/DL6.data.json'),
  DL7: require('./district/DL7.data.json'),
  DN1: require('./district/DN1.data.json'),
  DN11: require('./district/DN11.data.json'),
  DN14: require('./district/DN14.data.json'),
  DN15: require('./district/DN15.data.json'),
  DN16: require('./district/DN16.data.json'),
  DN17: require('./district/DN17.data.json'),
  DN19: require('./district/DN19.data.json'),
  DN2: require('./district/DN2.data.json'),
  DN21: require('./district/DN21.data.json'),
  DN22: require('./district/DN22.data.json'),
  DN3: require('./district/DN3.data.json'),
  DN31: require('./district/DN31.data.json'),
  DN33: require('./district/DN33.data.json'),
  DN34: require('./district/DN34.data.json'),
  DN35: require('./district/DN35.data.json'),
  DN36: require('./district/DN36.data.json'),
  DN4: require('./district/DN4.data.json'),
  DN40: require('./district/DN40.data.json'),
  DN5: require('./district/DN5.data.json'),
  DN6: require('./district/DN6.data.json'),
  DN7: require('./district/DN7.data.json'),
  DN9: require('./district/DN9.data.json'),
  DT1: require('./district/DT1.data.json'),
  DT11: require('./district/DT11.data.json'),
  DT2: require('./district/DT2.data.json'),
  DT3: require('./district/DT3.data.json'),
  DT4: require('./district/DT4.data.json'),
  DT5: require('./district/DT5.data.json'),
  DT6: require('./district/DT6.data.json'),
  DT9: require('./district/DT9.data.json'),
  DY1: require('./district/DY1.data.json'),
  DY10: require('./district/DY10.data.json'),
  DY11: require('./district/DY11.data.json'),
  DY2: require('./district/DY2.data.json'),
  DY4: require('./district/DY4.data.json'),
  DY5: require('./district/DY5.data.json'),
  DY8: require('./district/DY8.data.json'),
  DY9: require('./district/DY9.data.json'),
  E1: require('./district/E1.data.json'),
  E11: require('./district/E11.data.json'),
  E12: require('./district/E12.data.json'),
  E13: require('./district/E13.data.json'),
  E14: require('./district/E14.data.json'),
  E15: require('./district/E15.data.json'),
  E16: require('./district/E16.data.json'),
  E17: require('./district/E17.data.json'),
  E18: require('./district/E18.data.json'),
  E1W: require('./district/E1W.data.json'),
  E2: require('./district/E2.data.json'),
  E3: require('./district/E3.data.json'),
  E4: require('./district/E4.data.json'),
  E5: require('./district/E5.data.json'),
  E6: require('./district/E6.data.json'),
  E7: require('./district/E7.data.json'),
  E8: require('./district/E8.data.json'),
  E9: require('./district/E9.data.json'),
  EC1A: require('./district/EC1A.data.json'),
  EC1M: require('./district/EC1M.data.json'),
  EC1N: require('./district/EC1N.data.json'),
  EC1R: require('./district/EC1R.data.json'),
  EC1V: require('./district/EC1V.data.json'),
  EC1Y: require('./district/EC1Y.data.json'),
  EC2A: require('./district/EC2A.data.json'),
  EC2M: require('./district/EC2M.data.json'),
  EC2N: require('./district/EC2N.data.json'),
  EC2P: require('./district/EC2P.data.json'),
  EC2R: require('./district/EC2R.data.json'),
  EC2V: require('./district/EC2V.data.json'),
  EC2Y: require('./district/EC2Y.data.json'),
  EC3A: require('./district/EC3A.data.json'),
  EC3M: require('./district/EC3M.data.json'),
  EC3N: require('./district/EC3N.data.json'),
  EC3R: require('./district/EC3R.data.json'),
  EC3V: require('./district/EC3V.data.json'),
  EC4A: require('./district/EC4A.data.json'),
  EC4M: require('./district/EC4M.data.json'),
  EC4N: require('./district/EC4N.data.json'),
  EC4R: require('./district/EC4R.data.json'),
  EC4V: require('./district/EC4V.data.json'),
  EC4Y: require('./district/EC4Y.data.json'),
  EH1: require('./district/EH1.data.json'),
  EH10: require('./district/EH10.data.json'),
  EH11: require('./district/EH11.data.json'),
  EH12: require('./district/EH12.data.json'),
  EH13: require('./district/EH13.data.json'),
  EH14: require('./district/EH14.data.json'),
  EH15: require('./district/EH15.data.json'),
  EH16: require('./district/EH16.data.json'),
  EH17: require('./district/EH17.data.json'),
  EH19: require('./district/EH19.data.json'),
  EH2: require('./district/EH2.data.json'),
  EH20: require('./district/EH20.data.json'),
  EH21: require('./district/EH21.data.json'),
  EH22: require('./district/EH22.data.json'),
  EH23: require('./district/EH23.data.json'),
  EH25: require('./district/EH25.data.json'),
  EH26: require('./district/EH26.data.json'),
  EH28: require('./district/EH28.data.json'),
  EH3: require('./district/EH3.data.json'),
  EH30: require('./district/EH30.data.json'),
  EH31: require('./district/EH31.data.json'),
  EH33: require('./district/EH33.data.json'),
  EH37: require('./district/EH37.data.json'),
  EH39: require('./district/EH39.data.json'),
  EH4: require('./district/EH4.data.json'),
  EH41: require('./district/EH41.data.json'),
  EH42: require('./district/EH42.data.json'),
  EH45: require('./district/EH45.data.json'),
  EH46: require('./district/EH46.data.json'),
  EH47: require('./district/EH47.data.json'),
  EH48: require('./district/EH48.data.json'),
  EH49: require('./district/EH49.data.json'),
  EH5: require('./district/EH5.data.json'),
  EH51: require('./district/EH51.data.json'),
  EH52: require('./district/EH52.data.json'),
  EH53: require('./district/EH53.data.json'),
  EH54: require('./district/EH54.data.json'),
  EH55: require('./district/EH55.data.json'),
  EH6: require('./district/EH6.data.json'),
  EH7: require('./district/EH7.data.json'),
  EH8: require('./district/EH8.data.json'),
  EH9: require('./district/EH9.data.json'),
  EN1: require('./district/EN1.data.json'),
  EN10: require('./district/EN10.data.json'),
  EN11: require('./district/EN11.data.json'),
  EN2: require('./district/EN2.data.json'),
  EN3: require('./district/EN3.data.json'),
  EN4: require('./district/EN4.data.json'),
  EN5: require('./district/EN5.data.json'),
  EN6: require('./district/EN6.data.json'),
  EN7: require('./district/EN7.data.json'),
  EN8: require('./district/EN8.data.json'),
  EX1: require('./district/EX1.data.json'),
  EX10: require('./district/EX10.data.json'),
  EX11: require('./district/EX11.data.json'),
  EX12: require('./district/EX12.data.json'),
  EX13: require('./district/EX13.data.json'),
  EX14: require('./district/EX14.data.json'),
  EX16: require('./district/EX16.data.json'),
  EX17: require('./district/EX17.data.json'),
  EX2: require('./district/EX2.data.json'),
  EX20: require('./district/EX20.data.json'),
  EX22: require('./district/EX22.data.json'),
  EX23: require('./district/EX23.data.json'),
  EX3: require('./district/EX3.data.json'),
  EX31: require('./district/EX31.data.json'),
  EX32: require('./district/EX32.data.json'),
  EX33: require('./district/EX33.data.json'),
  EX34: require('./district/EX34.data.json'),
  EX35: require('./district/EX35.data.json'),
  EX36: require('./district/EX36.data.json'),
  EX38: require('./district/EX38.data.json'),
  EX39: require('./district/EX39.data.json'),
  EX4: require('./district/EX4.data.json'),
  EX5: require('./district/EX5.data.json'),
  EX6: require('./district/EX6.data.json'),
  EX7: require('./district/EX7.data.json'),
  EX8: require('./district/EX8.data.json'),
  EX9: require('./district/EX9.data.json'),
  FK1: require('./district/FK1.data.json'),
  FK10: require('./district/FK10.data.json'),
  FK11: require('./district/FK11.data.json'),
  FK14: require('./district/FK14.data.json'),
  FK16: require('./district/FK16.data.json'),
  FK17: require('./district/FK17.data.json'),
  FK2: require('./district/FK2.data.json'),
  FK3: require('./district/FK3.data.json'),
  FK5: require('./district/FK5.data.json'),
  FK7: require('./district/FK7.data.json'),
  FK8: require('./district/FK8.data.json'),
  FK9: require('./district/FK9.data.json'),
  FY1: require('./district/FY1.data.json'),
  FY2: require('./district/FY2.data.json'),
  FY3: require('./district/FY3.data.json'),
  FY4: require('./district/FY4.data.json'),
  FY5: require('./district/FY5.data.json'),
  FY7: require('./district/FY7.data.json'),
  FY8: require('./district/FY8.data.json'),
  G1: require('./district/G1.data.json'),
  G11: require('./district/G11.data.json'),
  G12: require('./district/G12.data.json'),
  G13: require('./district/G13.data.json'),
  G14: require('./district/G14.data.json'),
  G15: require('./district/G15.data.json'),
  G2: require('./district/G2.data.json'),
  G20: require('./district/G20.data.json'),
  G21: require('./district/G21.data.json'),
  G3: require('./district/G3.data.json'),
  G31: require('./district/G31.data.json'),
  G32: require('./district/G32.data.json'),
  G33: require('./district/G33.data.json'),
  G34: require('./district/G34.data.json'),
  G4: require('./district/G4.data.json'),
  G40: require('./district/G40.data.json'),
  G41: require('./district/G41.data.json'),
  G42: require('./district/G42.data.json'),
  G43: require('./district/G43.data.json'),
  G44: require('./district/G44.data.json'),
  G45: require('./district/G45.data.json'),
  G46: require('./district/G46.data.json'),
  G5: require('./district/G5.data.json'),
  G51: require('./district/G51.data.json'),
  G52: require('./district/G52.data.json'),
  G53: require('./district/G53.data.json'),
  G61: require('./district/G61.data.json'),
  G63: require('./district/G63.data.json'),
  G64: require('./district/G64.data.json'),
  G66: require('./district/G66.data.json'),
  G67: require('./district/G67.data.json'),
  G68: require('./district/G68.data.json'),
  G69: require('./district/G69.data.json'),
  G71: require('./district/G71.data.json'),
  G72: require('./district/G72.data.json'),
  G73: require('./district/G73.data.json'),
  G74: require('./district/G74.data.json'),
  G75: require('./district/G75.data.json'),
  G76: require('./district/G76.data.json'),
  G77: require('./district/G77.data.json'),
  G78: require('./district/G78.data.json'),
  G81: require('./district/G81.data.json'),
  G82: require('./district/G82.data.json'),
  G83: require('./district/G83.data.json'),
  G84: require('./district/G84.data.json'),
  GL1: require('./district/GL1.data.json'),
  GL10: require('./district/GL10.data.json'),
  GL11: require('./district/GL11.data.json'),
  GL12: require('./district/GL12.data.json'),
  GL13: require('./district/GL13.data.json'),
  GL14: require('./district/GL14.data.json'),
  GL15: require('./district/GL15.data.json'),
  GL16: require('./district/GL16.data.json'),
  GL17: require('./district/GL17.data.json'),
  GL19: require('./district/GL19.data.json'),
  GL2: require('./district/GL2.data.json'),
  GL20: require('./district/GL20.data.json'),
  GL3: require('./district/GL3.data.json'),
  GL4: require('./district/GL4.data.json'),
  GL5: require('./district/GL5.data.json'),
  GL50: require('./district/GL50.data.json'),
  GL51: require('./district/GL51.data.json'),
  GL52: require('./district/GL52.data.json'),
  GL53: require('./district/GL53.data.json'),
  GL54: require('./district/GL54.data.json'),
  GL55: require('./district/GL55.data.json'),
  GL56: require('./district/GL56.data.json'),
  GL6: require('./district/GL6.data.json'),
  GL7: require('./district/GL7.data.json'),
  GU1: require('./district/GU1.data.json'),
  GU10: require('./district/GU10.data.json'),
  GU11: require('./district/GU11.data.json'),
  GU12: require('./district/GU12.data.json'),
  GU14: require('./district/GU14.data.json'),
  GU15: require('./district/GU15.data.json'),
  GU16: require('./district/GU16.data.json'),
  GU17: require('./district/GU17.data.json'),
  GU18: require('./district/GU18.data.json'),
  GU2: require('./district/GU2.data.json'),
  GU21: require('./district/GU21.data.json'),
  GU22: require('./district/GU22.data.json'),
  GU23: require('./district/GU23.data.json'),
  GU24: require('./district/GU24.data.json'),
  GU26: require('./district/GU26.data.json'),
  GU28: require('./district/GU28.data.json'),
  GU29: require('./district/GU29.data.json'),
  GU3: require('./district/GU3.data.json'),
  GU30: require('./district/GU30.data.json'),
  GU31: require('./district/GU31.data.json'),
  GU32: require('./district/GU32.data.json'),
  GU34: require('./district/GU34.data.json'),
  GU35: require('./district/GU35.data.json'),
  GU4: require('./district/GU4.data.json'),
  GU47: require('./district/GU47.data.json'),
  GU5: require('./district/GU5.data.json'),
  GU51: require('./district/GU51.data.json'),
  GU6: require('./district/GU6.data.json'),
  GU7: require('./district/GU7.data.json'),
  GU9: require('./district/GU9.data.json'),
  GY1: require('./district/GY1.data.json'),
  HA0: require('./district/HA0.data.json'),
  HA1: require('./district/HA1.data.json'),
  HA2: require('./district/HA2.data.json'),
  HA3: require('./district/HA3.data.json'),
  HA4: require('./district/HA4.data.json'),
  HA5: require('./district/HA5.data.json'),
  HA6: require('./district/HA6.data.json'),
  HA7: require('./district/HA7.data.json'),
  HA8: require('./district/HA8.data.json'),
  HA9: require('./district/HA9.data.json'),
  HD1: require('./district/HD1.data.json'),
  HD2: require('./district/HD2.data.json'),
  HD3: require('./district/HD3.data.json'),
  HD4: require('./district/HD4.data.json'),
  HD5: require('./district/HD5.data.json'),
  HD6: require('./district/HD6.data.json'),
  HD7: require('./district/HD7.data.json'),
  HD8: require('./district/HD8.data.json'),
  HD9: require('./district/HD9.data.json'),
  HG1: require('./district/HG1.data.json'),
  HG2: require('./district/HG2.data.json'),
  HG4: require('./district/HG4.data.json'),
  HG5: require('./district/HG5.data.json'),
  HP1: require('./district/HP1.data.json'),
  HP10: require('./district/HP10.data.json'),
  HP11: require('./district/HP11.data.json'),
  HP12: require('./district/HP12.data.json'),
  HP13: require('./district/HP13.data.json'),
  HP14: require('./district/HP14.data.json'),
  HP15: require('./district/HP15.data.json'),
  HP18: require('./district/HP18.data.json'),
  HP19: require('./district/HP19.data.json'),
  HP2: require('./district/HP2.data.json'),
  HP20: require('./district/HP20.data.json'),
  HP21: require('./district/HP21.data.json'),
  HP23: require('./district/HP23.data.json'),
  HP27: require('./district/HP27.data.json'),
  HP3: require('./district/HP3.data.json'),
  HP4: require('./district/HP4.data.json'),
  HP5: require('./district/HP5.data.json'),
  HP6: require('./district/HP6.data.json'),
  HP7: require('./district/HP7.data.json'),
  HP8: require('./district/HP8.data.json'),
  HP9: require('./district/HP9.data.json'),
  HR1: require('./district/HR1.data.json'),
  HR2: require('./district/HR2.data.json'),
  HR3: require('./district/HR3.data.json'),
  HR4: require('./district/HR4.data.json'),
  HR5: require('./district/HR5.data.json'),
  HR6: require('./district/HR6.data.json'),
  HR7: require('./district/HR7.data.json'),
  HR8: require('./district/HR8.data.json'),
  HR9: require('./district/HR9.data.json'),
  HS1: require('./district/HS1.data.json'),
  HS2: require('./district/HS2.data.json'),
  HS3: require('./district/HS3.data.json'),
  HS6: require('./district/HS6.data.json'),
  HS7: require('./district/HS7.data.json'),
  HS8: require('./district/HS8.data.json'),
  HS9: require('./district/HS9.data.json'),
  HU1: require('./district/HU1.data.json'),
  HU10: require('./district/HU10.data.json'),
  HU11: require('./district/HU11.data.json'),
  HU12: require('./district/HU12.data.json'),
  HU13: require('./district/HU13.data.json'),
  HU14: require('./district/HU14.data.json'),
  HU15: require('./district/HU15.data.json'),
  HU16: require('./district/HU16.data.json'),
  HU17: require('./district/HU17.data.json'),
  HU18: require('./district/HU18.data.json'),
  HU19: require('./district/HU19.data.json'),
  HU2: require('./district/HU2.data.json'),
  HU3: require('./district/HU3.data.json'),
  HU4: require('./district/HU4.data.json'),
  HU5: require('./district/HU5.data.json'),
  HU6: require('./district/HU6.data.json'),
  HU7: require('./district/HU7.data.json'),
  HU8: require('./district/HU8.data.json'),
  HU9: require('./district/HU9.data.json'),
  HX1: require('./district/HX1.data.json'),
  HX3: require('./district/HX3.data.json'),
  HX6: require('./district/HX6.data.json'),
  HX7: require('./district/HX7.data.json'),
  IG1: require('./district/IG1.data.json'),
  IG10: require('./district/IG10.data.json'),
  IG11: require('./district/IG11.data.json'),
  IG2: require('./district/IG2.data.json'),
  IG3: require('./district/IG3.data.json'),
  IG5: require('./district/IG5.data.json'),
  IG6: require('./district/IG6.data.json'),
  IG8: require('./district/IG8.data.json'),
  IM1: require('./district/IM1.data.json'),
  IM4: require('./district/IM4.data.json'),
  IP1: require('./district/IP1.data.json'),
  IP11: require('./district/IP11.data.json'),
  IP12: require('./district/IP12.data.json'),
  IP13: require('./district/IP13.data.json'),
  IP14: require('./district/IP14.data.json'),
  IP15: require('./district/IP15.data.json'),
  IP16: require('./district/IP16.data.json'),
  IP17: require('./district/IP17.data.json'),
  IP18: require('./district/IP18.data.json'),
  IP19: require('./district/IP19.data.json'),
  IP2: require('./district/IP2.data.json'),
  IP22: require('./district/IP22.data.json'),
  IP23: require('./district/IP23.data.json'),
  IP24: require('./district/IP24.data.json'),
  IP26: require('./district/IP26.data.json'),
  IP27: require('./district/IP27.data.json'),
  IP28: require('./district/IP28.data.json'),
  IP29: require('./district/IP29.data.json'),
  IP3: require('./district/IP3.data.json'),
  IP30: require('./district/IP30.data.json'),
  IP32: require('./district/IP32.data.json'),
  IP33: require('./district/IP33.data.json'),
  IP4: require('./district/IP4.data.json'),
  IP5: require('./district/IP5.data.json'),
  IP6: require('./district/IP6.data.json'),
  IP7: require('./district/IP7.data.json'),
  IV1: require('./district/IV1.data.json'),
  IV11: require('./district/IV11.data.json'),
  IV12: require('./district/IV12.data.json'),
  IV14: require('./district/IV14.data.json'),
  IV15: require('./district/IV15.data.json'),
  IV17: require('./district/IV17.data.json'),
  IV19: require('./district/IV19.data.json'),
  IV2: require('./district/IV2.data.json'),
  IV20: require('./district/IV20.data.json'),
  IV21: require('./district/IV21.data.json'),
  IV22: require('./district/IV22.data.json'),
  IV25: require('./district/IV25.data.json'),
  IV26: require('./district/IV26.data.json'),
  IV27: require('./district/IV27.data.json'),
  IV3: require('./district/IV3.data.json'),
  IV30: require('./district/IV30.data.json'),
  IV36: require('./district/IV36.data.json'),
  IV4: require('./district/IV4.data.json'),
  IV63: require('./district/IV63.data.json'),
  IV7: require('./district/IV7.data.json'),
  IV9: require('./district/IV9.data.json'),
  JE2: require('./district/JE2.data.json'),
  KA1: require('./district/KA1.data.json'),
  KA10: require('./district/KA10.data.json'),
  KA12: require('./district/KA12.data.json'),
  KA13: require('./district/KA13.data.json'),
  KA14: require('./district/KA14.data.json'),
  KA15: require('./district/KA15.data.json'),
  KA18: require('./district/KA18.data.json'),
  KA19: require('./district/KA19.data.json'),
  KA21: require('./district/KA21.data.json'),
  KA23: require('./district/KA23.data.json'),
  KA26: require('./district/KA26.data.json'),
  KA29: require('./district/KA29.data.json'),
  KA3: require('./district/KA3.data.json'),
  KA30: require('./district/KA30.data.json'),
  KA6: require('./district/KA6.data.json'),
  KA7: require('./district/KA7.data.json'),
  KA9: require('./district/KA9.data.json'),
  KT1: require('./district/KT1.data.json'),
  KT10: require('./district/KT10.data.json'),
  KT11: require('./district/KT11.data.json'),
  KT12: require('./district/KT12.data.json'),
  KT13: require('./district/KT13.data.json'),
  KT15: require('./district/KT15.data.json'),
  KT16: require('./district/KT16.data.json'),
  KT17: require('./district/KT17.data.json'),
  KT18: require('./district/KT18.data.json'),
  KT19: require('./district/KT19.data.json'),
  KT2: require('./district/KT2.data.json'),
  KT21: require('./district/KT21.data.json'),
  KT22: require('./district/KT22.data.json'),
  KT23: require('./district/KT23.data.json'),
  KT3: require('./district/KT3.data.json'),
  KT4: require('./district/KT4.data.json'),
  KT6: require('./district/KT6.data.json'),
  KT7: require('./district/KT7.data.json'),
  KT8: require('./district/KT8.data.json'),
  KT9: require('./district/KT9.data.json'),
  KW14: require('./district/KW14.data.json'),
  KW15: require('./district/KW15.data.json'),
  KW16: require('./district/KW16.data.json'),
  KW17: require('./district/KW17.data.json'),
  KY1: require('./district/KY1.data.json'),
  KY10: require('./district/KY10.data.json'),
  KY11: require('./district/KY11.data.json'),
  KY12: require('./district/KY12.data.json'),
  KY13: require('./district/KY13.data.json'),
  KY14: require('./district/KY14.data.json'),
  KY15: require('./district/KY15.data.json'),
  KY16: require('./district/KY16.data.json'),
  KY2: require('./district/KY2.data.json'),
  KY3: require('./district/KY3.data.json'),
  KY4: require('./district/KY4.data.json'),
  KY5: require('./district/KY5.data.json'),
  KY6: require('./district/KY6.data.json'),
  KY7: require('./district/KY7.data.json'),
  KY8: require('./district/KY8.data.json'),
  L1: require('./district/L1.data.json'),
  L11: require('./district/L11.data.json'),
  L12: require('./district/L12.data.json'),
  L13: require('./district/L13.data.json'),
  L14: require('./district/L14.data.json'),
  L15: require('./district/L15.data.json'),
  L16: require('./district/L16.data.json'),
  L17: require('./district/L17.data.json'),
  L18: require('./district/L18.data.json'),
  L19: require('./district/L19.data.json'),
  L2: require('./district/L2.data.json'),
  L20: require('./district/L20.data.json'),
  L21: require('./district/L21.data.json'),
  L22: require('./district/L22.data.json'),
  L23: require('./district/L23.data.json'),
  L24: require('./district/L24.data.json'),
  L25: require('./district/L25.data.json'),
  L26: require('./district/L26.data.json'),
  L3: require('./district/L3.data.json'),
  L30: require('./district/L30.data.json'),
  L31: require('./district/L31.data.json'),
  L32: require('./district/L32.data.json'),
  L33: require('./district/L33.data.json'),
  L34: require('./district/L34.data.json'),
  L35: require('./district/L35.data.json'),
  L36: require('./district/L36.data.json'),
  L37: require('./district/L37.data.json'),
  L39: require('./district/L39.data.json'),
  L4: require('./district/L4.data.json'),
  L40: require('./district/L40.data.json'),
  L5: require('./district/L5.data.json'),
  L6: require('./district/L6.data.json'),
  L69: require('./district/L69.data.json'),
  L7: require('./district/L7.data.json'),
  L8: require('./district/L8.data.json'),
  L9: require('./district/L9.data.json'),
  LA1: require('./district/LA1.data.json'),
  LA10: require('./district/LA10.data.json'),
  LA11: require('./district/LA11.data.json'),
  LA12: require('./district/LA12.data.json'),
  LA13: require('./district/LA13.data.json'),
  LA14: require('./district/LA14.data.json'),
  LA18: require('./district/LA18.data.json'),
  LA2: require('./district/LA2.data.json'),
  LA20: require('./district/LA20.data.json'),
  LA21: require('./district/LA21.data.json'),
  LA22: require('./district/LA22.data.json'),
  LA23: require('./district/LA23.data.json'),
  LA3: require('./district/LA3.data.json'),
  LA4: require('./district/LA4.data.json'),
  LA5: require('./district/LA5.data.json'),
  LA7: require('./district/LA7.data.json'),
  LA8: require('./district/LA8.data.json'),
  LA9: require('./district/LA9.data.json'),
  LD1: require('./district/LD1.data.json'),
  LD3: require('./district/LD3.data.json'),
  LD6: require('./district/LD6.data.json'),
  LD8: require('./district/LD8.data.json'),
  LE1: require('./district/LE1.data.json'),
  LE10: require('./district/LE10.data.json'),
  LE11: require('./district/LE11.data.json'),
  LE12: require('./district/LE12.data.json'),
  LE13: require('./district/LE13.data.json'),
  LE14: require('./district/LE14.data.json'),
  LE15: require('./district/LE15.data.json'),
  LE16: require('./district/LE16.data.json'),
  LE17: require('./district/LE17.data.json'),
  LE18: require('./district/LE18.data.json'),
  LE19: require('./district/LE19.data.json'),
  LE2: require('./district/LE2.data.json'),
  LE3: require('./district/LE3.data.json'),
  LE4: require('./district/LE4.data.json'),
  LE5: require('./district/LE5.data.json'),
  LE67: require('./district/LE67.data.json'),
  LE7: require('./district/LE7.data.json'),
  LE8: require('./district/LE8.data.json'),
  LE9: require('./district/LE9.data.json'),
  LL11: require('./district/LL11.data.json'),
  LL12: require('./district/LL12.data.json'),
  LL13: require('./district/LL13.data.json'),
  LL14: require('./district/LL14.data.json'),
  LL15: require('./district/LL15.data.json'),
  LL17: require('./district/LL17.data.json'),
  LL18: require('./district/LL18.data.json'),
  LL20: require('./district/LL20.data.json'),
  LL22: require('./district/LL22.data.json'),
  LL23: require('./district/LL23.data.json'),
  LL24: require('./district/LL24.data.json'),
  LL28: require('./district/LL28.data.json'),
  LL29: require('./district/LL29.data.json'),
  LL30: require('./district/LL30.data.json'),
  LL31: require('./district/LL31.data.json'),
  LL32: require('./district/LL32.data.json'),
  LL33: require('./district/LL33.data.json'),
  LL40: require('./district/LL40.data.json'),
  LL42: require('./district/LL42.data.json'),
  LL46: require('./district/LL46.data.json'),
  LL49: require('./district/LL49.data.json'),
  LL53: require('./district/LL53.data.json'),
  LL54: require('./district/LL54.data.json'),
  LL55: require('./district/LL55.data.json'),
  LL57: require('./district/LL57.data.json'),
  LL58: require('./district/LL58.data.json'),
  LL61: require('./district/LL61.data.json'),
  LL65: require('./district/LL65.data.json'),
  LL74: require('./district/LL74.data.json'),
  LL77: require('./district/LL77.data.json'),
  LN1: require('./district/LN1.data.json'),
  LN10: require('./district/LN10.data.json'),
  LN11: require('./district/LN11.data.json'),
  LN12: require('./district/LN12.data.json'),
  LN2: require('./district/LN2.data.json'),
  LN3: require('./district/LN3.data.json'),
  LN4: require('./district/LN4.data.json'),
  LN5: require('./district/LN5.data.json'),
  LN6: require('./district/LN6.data.json'),
  LN7: require('./district/LN7.data.json'),
  LN8: require('./district/LN8.data.json'),
  LN9: require('./district/LN9.data.json'),
  LS1: require('./district/LS1.data.json'),
  LS10: require('./district/LS10.data.json'),
  LS11: require('./district/LS11.data.json'),
  LS12: require('./district/LS12.data.json'),
  LS13: require('./district/LS13.data.json'),
  LS14: require('./district/LS14.data.json'),
  LS15: require('./district/LS15.data.json'),
  LS16: require('./district/LS16.data.json'),
  LS17: require('./district/LS17.data.json'),
  LS18: require('./district/LS18.data.json'),
  LS19: require('./district/LS19.data.json'),
  LS2: require('./district/LS2.data.json'),
  LS20: require('./district/LS20.data.json'),
  LS21: require('./district/LS21.data.json'),
  LS22: require('./district/LS22.data.json'),
  LS23: require('./district/LS23.data.json'),
  LS24: require('./district/LS24.data.json'),
  LS25: require('./district/LS25.data.json'),
  LS27: require('./district/LS27.data.json'),
  LS28: require('./district/LS28.data.json'),
  LS29: require('./district/LS29.data.json'),
  LS3: require('./district/LS3.data.json'),
  LS4: require('./district/LS4.data.json'),
  LS5: require('./district/LS5.data.json'),
  LS6: require('./district/LS6.data.json'),
  LS7: require('./district/LS7.data.json'),
  LS8: require('./district/LS8.data.json'),
  LS9: require('./district/LS9.data.json'),
  LU1: require('./district/LU1.data.json'),
  LU2: require('./district/LU2.data.json'),
  LU3: require('./district/LU3.data.json'),
  LU4: require('./district/LU4.data.json'),
  LU5: require('./district/LU5.data.json'),
  LU6: require('./district/LU6.data.json'),
  LU7: require('./district/LU7.data.json'),
  M1: require('./district/M1.data.json'),
  M11: require('./district/M11.data.json'),
  M12: require('./district/M12.data.json'),
  M13: require('./district/M13.data.json'),
  M14: require('./district/M14.data.json'),
  M15: require('./district/M15.data.json'),
  M16: require('./district/M16.data.json'),
  M17: require('./district/M17.data.json'),
  M18: require('./district/M18.data.json'),
  M19: require('./district/M19.data.json'),
  M2: require('./district/M2.data.json'),
  M20: require('./district/M20.data.json'),
  M21: require('./district/M21.data.json'),
  M22: require('./district/M22.data.json'),
  M23: require('./district/M23.data.json'),
  M24: require('./district/M24.data.json'),
  M25: require('./district/M25.data.json'),
  M26: require('./district/M26.data.json'),
  M27: require('./district/M27.data.json'),
  M28: require('./district/M28.data.json'),
  M3: require('./district/M3.data.json'),
  M30: require('./district/M30.data.json'),
  M31: require('./district/M31.data.json'),
  M32: require('./district/M32.data.json'),
  M33: require('./district/M33.data.json'),
  M34: require('./district/M34.data.json'),
  M35: require('./district/M35.data.json'),
  M4: require('./district/M4.data.json'),
  M40: require('./district/M40.data.json'),
  M41: require('./district/M41.data.json'),
  M43: require('./district/M43.data.json'),
  M44: require('./district/M44.data.json'),
  M5: require('./district/M5.data.json'),
  M50: require('./district/M50.data.json'),
  M6: require('./district/M6.data.json'),
  M60: require('./district/M60.data.json'),
  M7: require('./district/M7.data.json'),
  M8: require('./district/M8.data.json'),
  M9: require('./district/M9.data.json'),
  M90: require('./district/M90.data.json'),
  ME1: require('./district/ME1.data.json'),
  ME10: require('./district/ME10.data.json'),
  ME12: require('./district/ME12.data.json'),
  ME13: require('./district/ME13.data.json'),
  ME14: require('./district/ME14.data.json'),
  ME15: require('./district/ME15.data.json'),
  ME16: require('./district/ME16.data.json'),
  ME17: require('./district/ME17.data.json'),
  ME18: require('./district/ME18.data.json'),
  ME19: require('./district/ME19.data.json'),
  ME2: require('./district/ME2.data.json'),
  ME3: require('./district/ME3.data.json'),
  ME4: require('./district/ME4.data.json'),
  ME5: require('./district/ME5.data.json'),
  ME7: require('./district/ME7.data.json'),
  ME8: require('./district/ME8.data.json'),
  ME9: require('./district/ME9.data.json'),
  MK1: require('./district/MK1.data.json'),
  MK10: require('./district/MK10.data.json'),
  MK11: require('./district/MK11.data.json'),
  MK12: require('./district/MK12.data.json'),
  MK14: require('./district/MK14.data.json'),
  MK15: require('./district/MK15.data.json'),
  MK16: require('./district/MK16.data.json'),
  MK17: require('./district/MK17.data.json'),
  MK18: require('./district/MK18.data.json'),
  MK19: require('./district/MK19.data.json'),
  MK3: require('./district/MK3.data.json'),
  MK4: require('./district/MK4.data.json'),
  MK40: require('./district/MK40.data.json'),
  MK41: require('./district/MK41.data.json'),
  MK42: require('./district/MK42.data.json'),
  MK43: require('./district/MK43.data.json'),
  MK44: require('./district/MK44.data.json'),
  MK45: require('./district/MK45.data.json'),
  MK46: require('./district/MK46.data.json'),
  MK5: require('./district/MK5.data.json'),
  MK6: require('./district/MK6.data.json'),
  MK7: require('./district/MK7.data.json'),
  MK8: require('./district/MK8.data.json'),
  MK9: require('./district/MK9.data.json'),
  ML1: require('./district/ML1.data.json'),
  ML10: require('./district/ML10.data.json'),
  ML11: require('./district/ML11.data.json'),
  ML12: require('./district/ML12.data.json'),
  ML2: require('./district/ML2.data.json'),
  ML3: require('./district/ML3.data.json'),
  ML4: require('./district/ML4.data.json'),
  ML5: require('./district/ML5.data.json'),
  ML6: require('./district/ML6.data.json'),
  ML8: require('./district/ML8.data.json'),
  ML9: require('./district/ML9.data.json'),
  N1: require('./district/N1.data.json'),
  N10: require('./district/N10.data.json'),
  N11: require('./district/N11.data.json'),
  N12: require('./district/N12.data.json'),
  N13: require('./district/N13.data.json'),
  N14: require('./district/N14.data.json'),
  N15: require('./district/N15.data.json'),
  N16: require('./district/N16.data.json'),
  N17: require('./district/N17.data.json'),
  N18: require('./district/N18.data.json'),
  N19: require('./district/N19.data.json'),
  N1C: require('./district/N1C.data.json'),
  N2: require('./district/N2.data.json'),
  N20: require('./district/N20.data.json'),
  N21: require('./district/N21.data.json'),
  N22: require('./district/N22.data.json'),
  N3: require('./district/N3.data.json'),
  N4: require('./district/N4.data.json'),
  N5: require('./district/N5.data.json'),
  N6: require('./district/N6.data.json'),
  N7: require('./district/N7.data.json'),
  N8: require('./district/N8.data.json'),
  N9: require('./district/N9.data.json'),
  NE1: require('./district/NE1.data.json'),
  NE10: require('./district/NE10.data.json'),
  NE11: require('./district/NE11.data.json'),
  NE12: require('./district/NE12.data.json'),
  NE13: require('./district/NE13.data.json'),
  NE15: require('./district/NE15.data.json'),
  NE16: require('./district/NE16.data.json'),
  NE2: require('./district/NE2.data.json'),
  NE20: require('./district/NE20.data.json'),
  NE21: require('./district/NE21.data.json'),
  NE22: require('./district/NE22.data.json'),
  NE23: require('./district/NE23.data.json'),
  NE24: require('./district/NE24.data.json'),
  NE25: require('./district/NE25.data.json'),
  NE26: require('./district/NE26.data.json'),
  NE27: require('./district/NE27.data.json'),
  NE28: require('./district/NE28.data.json'),
  NE29: require('./district/NE29.data.json'),
  NE3: require('./district/NE3.data.json'),
  NE30: require('./district/NE30.data.json'),
  NE31: require('./district/NE31.data.json'),
  NE32: require('./district/NE32.data.json'),
  NE34: require('./district/NE34.data.json'),
  NE35: require('./district/NE35.data.json'),
  NE38: require('./district/NE38.data.json'),
  NE39: require('./district/NE39.data.json'),
  NE4: require('./district/NE4.data.json'),
  NE42: require('./district/NE42.data.json'),
  NE43: require('./district/NE43.data.json'),
  NE46: require('./district/NE46.data.json'),
  NE48: require('./district/NE48.data.json'),
  NE49: require('./district/NE49.data.json'),
  NE5: require('./district/NE5.data.json'),
  NE6: require('./district/NE6.data.json'),
  NE61: require('./district/NE61.data.json'),
  NE62: require('./district/NE62.data.json'),
  NE63: require('./district/NE63.data.json'),
  NE65: require('./district/NE65.data.json'),
  NE66: require('./district/NE66.data.json'),
  NE68: require('./district/NE68.data.json'),
  NE7: require('./district/NE7.data.json'),
  NE8: require('./district/NE8.data.json'),
  NE9: require('./district/NE9.data.json'),
  NE99: require('./district/NE99.data.json'),
  NG1: require('./district/NG1.data.json'),
  NG10: require('./district/NG10.data.json'),
  NG11: require('./district/NG11.data.json'),
  NG12: require('./district/NG12.data.json'),
  NG13: require('./district/NG13.data.json'),
  NG15: require('./district/NG15.data.json'),
  NG16: require('./district/NG16.data.json'),
  NG17: require('./district/NG17.data.json'),
  NG18: require('./district/NG18.data.json'),
  NG2: require('./district/NG2.data.json'),
  NG21: require('./district/NG21.data.json'),
  NG22: require('./district/NG22.data.json'),
  NG23: require('./district/NG23.data.json'),
  NG24: require('./district/NG24.data.json'),
  NG3: require('./district/NG3.data.json'),
  NG31: require('./district/NG31.data.json'),
  NG32: require('./district/NG32.data.json'),
  NG34: require('./district/NG34.data.json'),
  NG4: require('./district/NG4.data.json'),
  NG5: require('./district/NG5.data.json'),
  NG6: require('./district/NG6.data.json'),
  NG7: require('./district/NG7.data.json'),
  NG8: require('./district/NG8.data.json'),
  NG80: require('./district/NG80.data.json'),
  NG9: require('./district/NG9.data.json'),
  NG90: require('./district/NG90.data.json'),
  NN1: require('./district/NN1.data.json'),
  NN10: require('./district/NN10.data.json'),
  NN11: require('./district/NN11.data.json'),
  NN12: require('./district/NN12.data.json'),
  NN13: require('./district/NN13.data.json'),
  NN14: require('./district/NN14.data.json'),
  NN15: require('./district/NN15.data.json'),
  NN16: require('./district/NN16.data.json'),
  NN17: require('./district/NN17.data.json'),
  NN18: require('./district/NN18.data.json'),
  NN2: require('./district/NN2.data.json'),
  NN29: require('./district/NN29.data.json'),
  NN3: require('./district/NN3.data.json'),
  NN4: require('./district/NN4.data.json'),
  NN5: require('./district/NN5.data.json'),
  NN6: require('./district/NN6.data.json'),
  NN7: require('./district/NN7.data.json'),
  NN8: require('./district/NN8.data.json'),
  NN9: require('./district/NN9.data.json'),
  NP10: require('./district/NP10.data.json'),
  NP11: require('./district/NP11.data.json'),
  NP13: require('./district/NP13.data.json'),
  NP15: require('./district/NP15.data.json'),
  NP16: require('./district/NP16.data.json'),
  NP18: require('./district/NP18.data.json'),
  NP19: require('./district/NP19.data.json'),
  NP20: require('./district/NP20.data.json'),
  NP23: require('./district/NP23.data.json'),
  NP26: require('./district/NP26.data.json'),
  NP4: require('./district/NP4.data.json'),
  NP44: require('./district/NP44.data.json'),
  NP7: require('./district/NP7.data.json'),
  NP8: require('./district/NP8.data.json'),
  NR1: require('./district/NR1.data.json'),
  NR10: require('./district/NR10.data.json'),
  NR11: require('./district/NR11.data.json'),
  NR12: require('./district/NR12.data.json'),
  NR13: require('./district/NR13.data.json'),
  NR14: require('./district/NR14.data.json'),
  NR16: require('./district/NR16.data.json'),
  NR17: require('./district/NR17.data.json'),
  NR19: require('./district/NR19.data.json'),
  NR2: require('./district/NR2.data.json'),
  NR23: require('./district/NR23.data.json'),
  NR25: require('./district/NR25.data.json'),
  NR26: require('./district/NR26.data.json'),
  NR27: require('./district/NR27.data.json'),
  NR28: require('./district/NR28.data.json'),
  NR29: require('./district/NR29.data.json'),
  NR3: require('./district/NR3.data.json'),
  NR30: require('./district/NR30.data.json'),
  NR31: require('./district/NR31.data.json'),
  NR32: require('./district/NR32.data.json'),
  NR33: require('./district/NR33.data.json'),
  NR34: require('./district/NR34.data.json'),
  NR35: require('./district/NR35.data.json'),
  NR4: require('./district/NR4.data.json'),
  NR5: require('./district/NR5.data.json'),
  NR6: require('./district/NR6.data.json'),
  NR7: require('./district/NR7.data.json'),
  NR9: require('./district/NR9.data.json'),
  NW1: require('./district/NW1.data.json'),
  NW10: require('./district/NW10.data.json'),
  NW11: require('./district/NW11.data.json'),
  NW2: require('./district/NW2.data.json'),
  NW3: require('./district/NW3.data.json'),
  NW4: require('./district/NW4.data.json'),
  NW5: require('./district/NW5.data.json'),
  NW6: require('./district/NW6.data.json'),
  NW7: require('./district/NW7.data.json'),
  NW8: require('./district/NW8.data.json'),
  NW9: require('./district/NW9.data.json'),
  OL1: require('./district/OL1.data.json'),
  OL10: require('./district/OL10.data.json'),
  OL11: require('./district/OL11.data.json'),
  OL12: require('./district/OL12.data.json'),
  OL14: require('./district/OL14.data.json'),
  OL16: require('./district/OL16.data.json'),
  OL2: require('./district/OL2.data.json'),
  OL3: require('./district/OL3.data.json'),
  OL4: require('./district/OL4.data.json'),
  OL5: require('./district/OL5.data.json'),
  OL6: require('./district/OL6.data.json'),
  OL7: require('./district/OL7.data.json'),
  OL8: require('./district/OL8.data.json'),
  OL9: require('./district/OL9.data.json'),
  OX1: require('./district/OX1.data.json'),
  OX10: require('./district/OX10.data.json'),
  OX11: require('./district/OX11.data.json'),
  OX12: require('./district/OX12.data.json'),
  OX13: require('./district/OX13.data.json'),
  OX14: require('./district/OX14.data.json'),
  OX15: require('./district/OX15.data.json'),
  OX16: require('./district/OX16.data.json'),
  OX18: require('./district/OX18.data.json'),
  OX2: require('./district/OX2.data.json'),
  OX20: require('./district/OX20.data.json'),
  OX25: require('./district/OX25.data.json'),
  OX26: require('./district/OX26.data.json'),
  OX28: require('./district/OX28.data.json'),
  OX29: require('./district/OX29.data.json'),
  OX3: require('./district/OX3.data.json'),
  OX33: require('./district/OX33.data.json'),
  OX39: require('./district/OX39.data.json'),
  OX4: require('./district/OX4.data.json'),
  OX44: require('./district/OX44.data.json'),
  OX49: require('./district/OX49.data.json'),
  OX5: require('./district/OX5.data.json'),
  OX7: require('./district/OX7.data.json'),
  OX9: require('./district/OX9.data.json'),
  PA1: require('./district/PA1.data.json'),
  PA11: require('./district/PA11.data.json'),
  PA14: require('./district/PA14.data.json'),
  PA15: require('./district/PA15.data.json'),
  PA18: require('./district/PA18.data.json'),
  PA19: require('./district/PA19.data.json'),
  PA2: require('./district/PA2.data.json'),
  PA21: require('./district/PA21.data.json'),
  PA23: require('./district/PA23.data.json'),
  PA28: require('./district/PA28.data.json'),
  PA29: require('./district/PA29.data.json'),
  PA3: require('./district/PA3.data.json'),
  PA31: require('./district/PA31.data.json'),
  PA34: require('./district/PA34.data.json'),
  PA35: require('./district/PA35.data.json'),
  PA36: require('./district/PA36.data.json'),
  PA37: require('./district/PA37.data.json'),
  PA4: require('./district/PA4.data.json'),
  PA48: require('./district/PA48.data.json'),
  PA75: require('./district/PA75.data.json'),
  PA77: require('./district/PA77.data.json'),
  PA8: require('./district/PA8.data.json'),
  PE1: require('./district/PE1.data.json'),
  PE10: require('./district/PE10.data.json'),
  PE11: require('./district/PE11.data.json'),
  PE12: require('./district/PE12.data.json'),
  PE13: require('./district/PE13.data.json'),
  PE14: require('./district/PE14.data.json'),
  PE15: require('./district/PE15.data.json'),
  PE16: require('./district/PE16.data.json'),
  PE19: require('./district/PE19.data.json'),
  PE2: require('./district/PE2.data.json'),
  PE21: require('./district/PE21.data.json'),
  PE23: require('./district/PE23.data.json'),
  PE24: require('./district/PE24.data.json'),
  PE25: require('./district/PE25.data.json'),
  PE27: require('./district/PE27.data.json'),
  PE28: require('./district/PE28.data.json'),
  PE29: require('./district/PE29.data.json'),
  PE3: require('./district/PE3.data.json'),
  PE30: require('./district/PE30.data.json'),
  PE31: require('./district/PE31.data.json'),
  PE34: require('./district/PE34.data.json'),
  PE36: require('./district/PE36.data.json'),
  PE37: require('./district/PE37.data.json'),
  PE38: require('./district/PE38.data.json'),
  PE4: require('./district/PE4.data.json'),
  PE6: require('./district/PE6.data.json'),
  PE7: require('./district/PE7.data.json'),
  PE8: require('./district/PE8.data.json'),
  PE9: require('./district/PE9.data.json'),
  PH1: require('./district/PH1.data.json'),
  PH10: require('./district/PH10.data.json'),
  PH14: require('./district/PH14.data.json'),
  PH15: require('./district/PH15.data.json'),
  PH16: require('./district/PH16.data.json'),
  PH2: require('./district/PH2.data.json'),
  PH22: require('./district/PH22.data.json'),
  PH23: require('./district/PH23.data.json'),
  PH26: require('./district/PH26.data.json'),
  PH3: require('./district/PH3.data.json'),
  PH33: require('./district/PH33.data.json'),
  PH34: require('./district/PH34.data.json'),
  PH36: require('./district/PH36.data.json'),
  PH38: require('./district/PH38.data.json'),
  PH6: require('./district/PH6.data.json'),
  PH7: require('./district/PH7.data.json'),
  PH9: require('./district/PH9.data.json'),
  PL1: require('./district/PL1.data.json'),
  PL10: require('./district/PL10.data.json'),
  PL11: require('./district/PL11.data.json'),
  PL12: require('./district/PL12.data.json'),
  PL13: require('./district/PL13.data.json'),
  PL14: require('./district/PL14.data.json'),
  PL15: require('./district/PL15.data.json'),
  PL17: require('./district/PL17.data.json'),
  PL19: require('./district/PL19.data.json'),
  PL2: require('./district/PL2.data.json'),
  PL20: require('./district/PL20.data.json'),
  PL21: require('./district/PL21.data.json'),
  PL23: require('./district/PL23.data.json'),
  PL24: require('./district/PL24.data.json'),
  PL25: require('./district/PL25.data.json'),
  PL26: require('./district/PL26.data.json'),
  PL28: require('./district/PL28.data.json'),
  PL3: require('./district/PL3.data.json'),
  PL30: require('./district/PL30.data.json'),
  PL31: require('./district/PL31.data.json'),
  PL4: require('./district/PL4.data.json'),
  PL5: require('./district/PL5.data.json'),
  PL6: require('./district/PL6.data.json'),
  PL7: require('./district/PL7.data.json'),
  PL8: require('./district/PL8.data.json'),
  PL9: require('./district/PL9.data.json'),
  PO1: require('./district/PO1.data.json'),
  PO10: require('./district/PO10.data.json'),
  PO11: require('./district/PO11.data.json'),
  PO12: require('./district/PO12.data.json'),
  PO13: require('./district/PO13.data.json'),
  PO14: require('./district/PO14.data.json'),
  PO15: require('./district/PO15.data.json'),
  PO16: require('./district/PO16.data.json'),
  PO17: require('./district/PO17.data.json'),
  PO18: require('./district/PO18.data.json'),
  PO19: require('./district/PO19.data.json'),
  PO2: require('./district/PO2.data.json'),
  PO20: require('./district/PO20.data.json'),
  PO21: require('./district/PO21.data.json'),
  PO22: require('./district/PO22.data.json'),
  PO3: require('./district/PO3.data.json'),
  PO30: require('./district/PO30.data.json'),
  PO31: require('./district/PO31.data.json'),
  PO32: require('./district/PO32.data.json'),
  PO33: require('./district/PO33.data.json'),
  PO34: require('./district/PO34.data.json'),
  PO36: require('./district/PO36.data.json'),
  PO38: require('./district/PO38.data.json'),
  PO4: require('./district/PO4.data.json'),
  PO5: require('./district/PO5.data.json'),
  PO6: require('./district/PO6.data.json'),
  PO7: require('./district/PO7.data.json'),
  PO8: require('./district/PO8.data.json'),
  PO9: require('./district/PO9.data.json'),
  PR1: require('./district/PR1.data.json'),
  PR2: require('./district/PR2.data.json'),
  PR26: require('./district/PR26.data.json'),
  PR3: require('./district/PR3.data.json'),
  PR4: require('./district/PR4.data.json'),
  PR5: require('./district/PR5.data.json'),
  PR6: require('./district/PR6.data.json'),
  PR7: require('./district/PR7.data.json'),
  PR8: require('./district/PR8.data.json'),
  PR9: require('./district/PR9.data.json'),
  RG1: require('./district/RG1.data.json'),
  RG10: require('./district/RG10.data.json'),
  RG12: require('./district/RG12.data.json'),
  RG14: require('./district/RG14.data.json'),
  RG17: require('./district/RG17.data.json'),
  RG18: require('./district/RG18.data.json'),
  RG19: require('./district/RG19.data.json'),
  RG2: require('./district/RG2.data.json'),
  RG20: require('./district/RG20.data.json'),
  RG21: require('./district/RG21.data.json'),
  RG22: require('./district/RG22.data.json'),
  RG24: require('./district/RG24.data.json'),
  RG25: require('./district/RG25.data.json'),
  RG27: require('./district/RG27.data.json'),
  RG29: require('./district/RG29.data.json'),
  RG30: require('./district/RG30.data.json'),
  RG4: require('./district/RG4.data.json'),
  RG40: require('./district/RG40.data.json'),
  RG41: require('./district/RG41.data.json'),
  RG42: require('./district/RG42.data.json'),
  RG45: require('./district/RG45.data.json'),
  RG5: require('./district/RG5.data.json'),
  RG6: require('./district/RG6.data.json'),
  RG7: require('./district/RG7.data.json'),
  RG8: require('./district/RG8.data.json'),
  RG9: require('./district/RG9.data.json'),
  RH1: require('./district/RH1.data.json'),
  RH10: require('./district/RH10.data.json'),
  RH11: require('./district/RH11.data.json'),
  RH12: require('./district/RH12.data.json'),
  RH13: require('./district/RH13.data.json'),
  RH14: require('./district/RH14.data.json'),
  RH15: require('./district/RH15.data.json'),
  RH16: require('./district/RH16.data.json'),
  RH17: require('./district/RH17.data.json'),
  RH18: require('./district/RH18.data.json'),
  RH19: require('./district/RH19.data.json'),
  RH2: require('./district/RH2.data.json'),
  RH20: require('./district/RH20.data.json'),
  RH3: require('./district/RH3.data.json'),
  RH4: require('./district/RH4.data.json'),
  RH6: require('./district/RH6.data.json'),
  RH7: require('./district/RH7.data.json'),
  RH8: require('./district/RH8.data.json'),
  RH9: require('./district/RH9.data.json'),
  RM1: require('./district/RM1.data.json'),
  RM10: require('./district/RM10.data.json'),
  RM11: require('./district/RM11.data.json'),
  RM12: require('./district/RM12.data.json'),
  RM13: require('./district/RM13.data.json'),
  RM14: require('./district/RM14.data.json'),
  RM16: require('./district/RM16.data.json'),
  RM17: require('./district/RM17.data.json'),
  RM18: require('./district/RM18.data.json'),
  RM20: require('./district/RM20.data.json'),
  RM3: require('./district/RM3.data.json'),
  RM6: require('./district/RM6.data.json'),
  RM7: require('./district/RM7.data.json'),
  RM8: require('./district/RM8.data.json'),
  RM9: require('./district/RM9.data.json'),
  S1: require('./district/S1.data.json'),
  S10: require('./district/S10.data.json'),
  S11: require('./district/S11.data.json'),
  S17: require('./district/S17.data.json'),
  S18: require('./district/S18.data.json'),
  S2: require('./district/S2.data.json'),
  S20: require('./district/S20.data.json'),
  S26: require('./district/S26.data.json'),
  S3: require('./district/S3.data.json'),
  S33: require('./district/S33.data.json'),
  S35: require('./district/S35.data.json'),
  S4: require('./district/S4.data.json'),
  S40: require('./district/S40.data.json'),
  S41: require('./district/S41.data.json'),
  S42: require('./district/S42.data.json'),
  S43: require('./district/S43.data.json'),
  S44: require('./district/S44.data.json'),
  S45: require('./district/S45.data.json'),
  S5: require('./district/S5.data.json'),
  S6: require('./district/S6.data.json'),
  S60: require('./district/S60.data.json'),
  S63: require('./district/S63.data.json'),
  S64: require('./district/S64.data.json'),
  S65: require('./district/S65.data.json'),
  S7: require('./district/S7.data.json'),
  S70: require('./district/S70.data.json'),
  S71: require('./district/S71.data.json'),
  S75: require('./district/S75.data.json'),
  S8: require('./district/S8.data.json'),
  S80: require('./district/S80.data.json'),
  S81: require('./district/S81.data.json'),
  S9: require('./district/S9.data.json'),
  SA1: require('./district/SA1.data.json'),
  SA10: require('./district/SA10.data.json'),
  SA11: require('./district/SA11.data.json'),
  SA12: require('./district/SA12.data.json'),
  SA13: require('./district/SA13.data.json'),
  SA14: require('./district/SA14.data.json'),
  SA15: require('./district/SA15.data.json'),
  SA16: require('./district/SA16.data.json'),
  SA17: require('./district/SA17.data.json'),
  SA18: require('./district/SA18.data.json'),
  SA19: require('./district/SA19.data.json'),
  SA2: require('./district/SA2.data.json'),
  SA20: require('./district/SA20.data.json'),
  SA3: require('./district/SA3.data.json'),
  SA31: require('./district/SA31.data.json'),
  SA32: require('./district/SA32.data.json'),
  SA33: require('./district/SA33.data.json'),
  SA34: require('./district/SA34.data.json'),
  SA38: require('./district/SA38.data.json'),
  SA39: require('./district/SA39.data.json'),
  SA4: require('./district/SA4.data.json'),
  SA43: require('./district/SA43.data.json'),
  SA44: require('./district/SA44.data.json'),
  SA46: require('./district/SA46.data.json'),
  SA48: require('./district/SA48.data.json'),
  SA5: require('./district/SA5.data.json'),
  SA6: require('./district/SA6.data.json'),
  SA61: require('./district/SA61.data.json'),
  SA62: require('./district/SA62.data.json'),
  SA66: require('./district/SA66.data.json'),
  SA67: require('./district/SA67.data.json'),
  SA68: require('./district/SA68.data.json'),
  SA69: require('./district/SA69.data.json'),
  SA7: require('./district/SA7.data.json'),
  SA71: require('./district/SA71.data.json'),
  SA72: require('./district/SA72.data.json'),
  SA73: require('./district/SA73.data.json'),
  SA9: require('./district/SA9.data.json'),
  SE1: require('./district/SE1.data.json'),
  SE10: require('./district/SE10.data.json'),
  SE11: require('./district/SE11.data.json'),
  SE12: require('./district/SE12.data.json'),
  SE13: require('./district/SE13.data.json'),
  SE14: require('./district/SE14.data.json'),
  SE15: require('./district/SE15.data.json'),
  SE16: require('./district/SE16.data.json'),
  SE17: require('./district/SE17.data.json'),
  SE18: require('./district/SE18.data.json'),
  SE19: require('./district/SE19.data.json'),
  SE20: require('./district/SE20.data.json'),
  SE21: require('./district/SE21.data.json'),
  SE22: require('./district/SE22.data.json'),
  SE23: require('./district/SE23.data.json'),
  SE24: require('./district/SE24.data.json'),
  SE25: require('./district/SE25.data.json'),
  SE26: require('./district/SE26.data.json'),
  SE27: require('./district/SE27.data.json'),
  SE28: require('./district/SE28.data.json'),
  SE3: require('./district/SE3.data.json'),
  SE4: require('./district/SE4.data.json'),
  SE5: require('./district/SE5.data.json'),
  SE6: require('./district/SE6.data.json'),
  SE7: require('./district/SE7.data.json'),
  SE8: require('./district/SE8.data.json'),
  SE9: require('./district/SE9.data.json'),
  SG1: require('./district/SG1.data.json'),
  SG11: require('./district/SG11.data.json'),
  SG12: require('./district/SG12.data.json'),
  SG13: require('./district/SG13.data.json'),
  SG14: require('./district/SG14.data.json'),
  SG17: require('./district/SG17.data.json'),
  SG18: require('./district/SG18.data.json'),
  SG19: require('./district/SG19.data.json'),
  SG2: require('./district/SG2.data.json'),
  SG4: require('./district/SG4.data.json'),
  SG6: require('./district/SG6.data.json'),
  SG7: require('./district/SG7.data.json'),
  SG8: require('./district/SG8.data.json'),
  SG9: require('./district/SG9.data.json'),
  SK1: require('./district/SK1.data.json'),
  SK10: require('./district/SK10.data.json'),
  SK11: require('./district/SK11.data.json'),
  SK12: require('./district/SK12.data.json'),
  SK13: require('./district/SK13.data.json'),
  SK14: require('./district/SK14.data.json'),
  SK15: require('./district/SK15.data.json'),
  SK16: require('./district/SK16.data.json'),
  SK17: require('./district/SK17.data.json'),
  SK2: require('./district/SK2.data.json'),
  SK23: require('./district/SK23.data.json'),
  SK3: require('./district/SK3.data.json'),
  SK4: require('./district/SK4.data.json'),
  SK5: require('./district/SK5.data.json'),
  SK6: require('./district/SK6.data.json'),
  SK7: require('./district/SK7.data.json'),
  SK8: require('./district/SK8.data.json'),
  SK9: require('./district/SK9.data.json'),
  SL1: require('./district/SL1.data.json'),
  SL2: require('./district/SL2.data.json'),
  SL3: require('./district/SL3.data.json'),
  SL4: require('./district/SL4.data.json'),
  SL5: require('./district/SL5.data.json'),
  SL6: require('./district/SL6.data.json'),
  SL7: require('./district/SL7.data.json'),
  SL8: require('./district/SL8.data.json'),
  SL9: require('./district/SL9.data.json'),
  SM1: require('./district/SM1.data.json'),
  SM2: require('./district/SM2.data.json'),
  SM3: require('./district/SM3.data.json'),
  SM4: require('./district/SM4.data.json'),
  SM5: require('./district/SM5.data.json'),
  SM6: require('./district/SM6.data.json'),
  SM7: require('./district/SM7.data.json'),
  SN1: require('./district/SN1.data.json'),
  SN10: require('./district/SN10.data.json'),
  SN12: require('./district/SN12.data.json'),
  SN13: require('./district/SN13.data.json'),
  SN14: require('./district/SN14.data.json'),
  SN15: require('./district/SN15.data.json'),
  SN16: require('./district/SN16.data.json'),
  SN2: require('./district/SN2.data.json'),
  SN26: require('./district/SN26.data.json'),
  SN3: require('./district/SN3.data.json'),
  SN4: require('./district/SN4.data.json'),
  SN5: require('./district/SN5.data.json'),
  SN6: require('./district/SN6.data.json'),
  SN7: require('./district/SN7.data.json'),
  SN8: require('./district/SN8.data.json'),
  SO14: require('./district/SO14.data.json'),
  SO15: require('./district/SO15.data.json'),
  SO16: require('./district/SO16.data.json'),
  SO17: require('./district/SO17.data.json'),
  SO19: require('./district/SO19.data.json'),
  SO20: require('./district/SO20.data.json'),
  SO21: require('./district/SO21.data.json'),
  SO22: require('./district/SO22.data.json'),
  SO23: require('./district/SO23.data.json'),
  SO24: require('./district/SO24.data.json'),
  SO30: require('./district/SO30.data.json'),
  SO31: require('./district/SO31.data.json'),
  SO32: require('./district/SO32.data.json'),
  SO40: require('./district/SO40.data.json'),
  SO41: require('./district/SO41.data.json'),
  SO42: require('./district/SO42.data.json'),
  SO43: require('./district/SO43.data.json'),
  SO45: require('./district/SO45.data.json'),
  SO50: require('./district/SO50.data.json'),
  SO51: require('./district/SO51.data.json'),
  SO53: require('./district/SO53.data.json'),
  SP1: require('./district/SP1.data.json'),
  SP10: require('./district/SP10.data.json'),
  SP2: require('./district/SP2.data.json'),
  SP3: require('./district/SP3.data.json'),
  SP4: require('./district/SP4.data.json'),
  SP5: require('./district/SP5.data.json'),
  SP6: require('./district/SP6.data.json'),
  SP7: require('./district/SP7.data.json'),
  SP8: require('./district/SP8.data.json'),
  SR1: require('./district/SR1.data.json'),
  SR2: require('./district/SR2.data.json'),
  SR3: require('./district/SR3.data.json'),
  SR4: require('./district/SR4.data.json'),
  SR5: require('./district/SR5.data.json'),
  SR6: require('./district/SR6.data.json'),
  SR7: require('./district/SR7.data.json'),
  SR8: require('./district/SR8.data.json'),
  SS0: require('./district/SS0.data.json'),
  SS1: require('./district/SS1.data.json'),
  SS11: require('./district/SS11.data.json'),
  SS13: require('./district/SS13.data.json'),
  SS14: require('./district/SS14.data.json'),
  SS15: require('./district/SS15.data.json'),
  SS16: require('./district/SS16.data.json'),
  SS2: require('./district/SS2.data.json'),
  SS3: require('./district/SS3.data.json'),
  SS4: require('./district/SS4.data.json'),
  SS5: require('./district/SS5.data.json'),
  SS6: require('./district/SS6.data.json'),
  SS7: require('./district/SS7.data.json'),
  SS8: require('./district/SS8.data.json'),
  SS9: require('./district/SS9.data.json'),
  ST1: require('./district/ST1.data.json'),
  ST10: require('./district/ST10.data.json'),
  ST11: require('./district/ST11.data.json'),
  ST13: require('./district/ST13.data.json'),
  ST14: require('./district/ST14.data.json'),
  ST15: require('./district/ST15.data.json'),
  ST16: require('./district/ST16.data.json'),
  ST17: require('./district/ST17.data.json'),
  ST3: require('./district/ST3.data.json'),
  ST4: require('./district/ST4.data.json'),
  ST5: require('./district/ST5.data.json'),
  ST6: require('./district/ST6.data.json'),
  ST9: require('./district/ST9.data.json'),
  SW10: require('./district/SW10.data.json'),
  SW11: require('./district/SW11.data.json'),
  SW12: require('./district/SW12.data.json'),
  SW13: require('./district/SW13.data.json'),
  SW14: require('./district/SW14.data.json'),
  SW15: require('./district/SW15.data.json'),
  SW16: require('./district/SW16.data.json'),
  SW17: require('./district/SW17.data.json'),
  SW18: require('./district/SW18.data.json'),
  SW19: require('./district/SW19.data.json'),
  SW1A: require('./district/SW1A.data.json'),
  SW1E: require('./district/SW1E.data.json'),
  SW1H: require('./district/SW1H.data.json'),
  SW1P: require('./district/SW1P.data.json'),
  SW1V: require('./district/SW1V.data.json'),
  SW1W: require('./district/SW1W.data.json'),
  SW1X: require('./district/SW1X.data.json'),
  SW1Y: require('./district/SW1Y.data.json'),
  SW2: require('./district/SW2.data.json'),
  SW20: require('./district/SW20.data.json'),
  SW3: require('./district/SW3.data.json'),
  SW4: require('./district/SW4.data.json'),
  SW5: require('./district/SW5.data.json'),
  SW6: require('./district/SW6.data.json'),
  SW7: require('./district/SW7.data.json'),
  SW8: require('./district/SW8.data.json'),
  SW9: require('./district/SW9.data.json'),
  SY1: require('./district/SY1.data.json'),
  SY10: require('./district/SY10.data.json'),
  SY11: require('./district/SY11.data.json'),
  SY13: require('./district/SY13.data.json'),
  SY14: require('./district/SY14.data.json'),
  SY15: require('./district/SY15.data.json'),
  SY16: require('./district/SY16.data.json'),
  SY2: require('./district/SY2.data.json'),
  SY20: require('./district/SY20.data.json'),
  SY23: require('./district/SY23.data.json'),
  SY24: require('./district/SY24.data.json'),
  SY25: require('./district/SY25.data.json'),
  SY3: require('./district/SY3.data.json'),
  SY4: require('./district/SY4.data.json'),
  SY7: require('./district/SY7.data.json'),
  SY8: require('./district/SY8.data.json'),
  SY9: require('./district/SY9.data.json'),
  TA1: require('./district/TA1.data.json'),
  TA10: require('./district/TA10.data.json'),
  TA12: require('./district/TA12.data.json'),
  TA13: require('./district/TA13.data.json'),
  TA15: require('./district/TA15.data.json'),
  TA18: require('./district/TA18.data.json'),
  TA19: require('./district/TA19.data.json'),
  TA2: require('./district/TA2.data.json'),
  TA20: require('./district/TA20.data.json'),
  TA21: require('./district/TA21.data.json'),
  TA22: require('./district/TA22.data.json'),
  TA23: require('./district/TA23.data.json'),
  TA24: require('./district/TA24.data.json'),
  TA4: require('./district/TA4.data.json'),
  TA5: require('./district/TA5.data.json'),
  TA6: require('./district/TA6.data.json'),
  TA7: require('./district/TA7.data.json'),
  TA8: require('./district/TA8.data.json'),
  TD1: require('./district/TD1.data.json'),
  TD15: require('./district/TD15.data.json'),
  TD2: require('./district/TD2.data.json'),
  TD4: require('./district/TD4.data.json'),
  TD5: require('./district/TD5.data.json'),
  TD6: require('./district/TD6.data.json'),
  TD7: require('./district/TD7.data.json'),
  TD8: require('./district/TD8.data.json'),
  TD9: require('./district/TD9.data.json'),
  TF1: require('./district/TF1.data.json'),
  TF10: require('./district/TF10.data.json'),
  TF13: require('./district/TF13.data.json'),
  TF2: require('./district/TF2.data.json'),
  TF3: require('./district/TF3.data.json'),
  TF4: require('./district/TF4.data.json'),
  TF7: require('./district/TF7.data.json'),
  TF8: require('./district/TF8.data.json'),
  TF9: require('./district/TF9.data.json'),
  TN1: require('./district/TN1.data.json'),
  TN10: require('./district/TN10.data.json'),
  TN11: require('./district/TN11.data.json'),
  TN13: require('./district/TN13.data.json'),
  TN14: require('./district/TN14.data.json'),
  TN16: require('./district/TN16.data.json'),
  TN17: require('./district/TN17.data.json'),
  TN18: require('./district/TN18.data.json'),
  TN2: require('./district/TN2.data.json'),
  TN22: require('./district/TN22.data.json'),
  TN23: require('./district/TN23.data.json'),
  TN24: require('./district/TN24.data.json'),
  TN25: require('./district/TN25.data.json'),
  TN27: require('./district/TN27.data.json'),
  TN28: require('./district/TN28.data.json'),
  TN29: require('./district/TN29.data.json'),
  TN30: require('./district/TN30.data.json'),
  TN31: require('./district/TN31.data.json'),
  TN32: require('./district/TN32.data.json'),
  TN33: require('./district/TN33.data.json'),
  TN34: require('./district/TN34.data.json'),
  TN35: require('./district/TN35.data.json'),
  TN37: require('./district/TN37.data.json'),
  TN38: require('./district/TN38.data.json'),
  TN4: require('./district/TN4.data.json'),
  TN40: require('./district/TN40.data.json'),
  TN6: require('./district/TN6.data.json'),
  TN7: require('./district/TN7.data.json'),
  TN8: require('./district/TN8.data.json'),
  TN9: require('./district/TN9.data.json'),
  TQ1: require('./district/TQ1.data.json'),
  TQ10: require('./district/TQ10.data.json'),
  TQ12: require('./district/TQ12.data.json'),
  TQ13: require('./district/TQ13.data.json'),
  TQ14: require('./district/TQ14.data.json'),
  TQ2: require('./district/TQ2.data.json'),
  TQ3: require('./district/TQ3.data.json'),
  TQ4: require('./district/TQ4.data.json'),
  TQ5: require('./district/TQ5.data.json'),
  TQ6: require('./district/TQ6.data.json'),
  TQ7: require('./district/TQ7.data.json'),
  TQ9: require('./district/TQ9.data.json'),
  TR1: require('./district/TR1.data.json'),
  TR10: require('./district/TR10.data.json'),
  TR11: require('./district/TR11.data.json'),
  TR12: require('./district/TR12.data.json'),
  TR13: require('./district/TR13.data.json'),
  TR14: require('./district/TR14.data.json'),
  TR15: require('./district/TR15.data.json'),
  TR16: require('./district/TR16.data.json'),
  TR18: require('./district/TR18.data.json'),
  TR20: require('./district/TR20.data.json'),
  TR21: require('./district/TR21.data.json'),
  TR24: require('./district/TR24.data.json'),
  TR25: require('./district/TR25.data.json'),
  TR26: require('./district/TR26.data.json'),
  TR27: require('./district/TR27.data.json'),
  TR3: require('./district/TR3.data.json'),
  TR7: require('./district/TR7.data.json'),
  TR8: require('./district/TR8.data.json'),
  TR9: require('./district/TR9.data.json'),
  TS1: require('./district/TS1.data.json'),
  TS10: require('./district/TS10.data.json'),
  TS11: require('./district/TS11.data.json'),
  TS12: require('./district/TS12.data.json'),
  TS14: require('./district/TS14.data.json'),
  TS16: require('./district/TS16.data.json'),
  TS17: require('./district/TS17.data.json'),
  TS19: require('./district/TS19.data.json'),
  TS2: require('./district/TS2.data.json'),
  TS21: require('./district/TS21.data.json'),
  TS23: require('./district/TS23.data.json'),
  TS24: require('./district/TS24.data.json'),
  TS25: require('./district/TS25.data.json'),
  TS27: require('./district/TS27.data.json'),
  TS3: require('./district/TS3.data.json'),
  TS4: require('./district/TS4.data.json'),
  TS5: require('./district/TS5.data.json'),
  TS6: require('./district/TS6.data.json'),
  TS7: require('./district/TS7.data.json'),
  TS8: require('./district/TS8.data.json'),
  TS9: require('./district/TS9.data.json'),
  TW1: require('./district/TW1.data.json'),
  TW10: require('./district/TW10.data.json'),
  TW11: require('./district/TW11.data.json'),
  TW12: require('./district/TW12.data.json'),
  TW13: require('./district/TW13.data.json'),
  TW14: require('./district/TW14.data.json'),
  TW15: require('./district/TW15.data.json'),
  TW16: require('./district/TW16.data.json'),
  TW17: require('./district/TW17.data.json'),
  TW19: require('./district/TW19.data.json'),
  TW20: require('./district/TW20.data.json'),
  TW3: require('./district/TW3.data.json'),
  TW5: require('./district/TW5.data.json'),
  TW6: require('./district/TW6.data.json'),
  TW7: require('./district/TW7.data.json'),
  TW8: require('./district/TW8.data.json'),
  TW9: require('./district/TW9.data.json'),
  UB1: require('./district/UB1.data.json'),
  UB10: require('./district/UB10.data.json'),
  UB2: require('./district/UB2.data.json'),
  UB3: require('./district/UB3.data.json'),
  UB4: require('./district/UB4.data.json'),
  UB5: require('./district/UB5.data.json'),
  UB6: require('./district/UB6.data.json'),
  UB7: require('./district/UB7.data.json'),
  UB8: require('./district/UB8.data.json'),
  UB9: require('./district/UB9.data.json'),
  W10: require('./district/W10.data.json'),
  W11: require('./district/W11.data.json'),
  W12: require('./district/W12.data.json'),
  W13: require('./district/W13.data.json'),
  W14: require('./district/W14.data.json'),
  W1A: require('./district/W1A.data.json'),
  W1B: require('./district/W1B.data.json'),
  W1C: require('./district/W1C.data.json'),
  W1D: require('./district/W1D.data.json'),
  W1F: require('./district/W1F.data.json'),
  W1G: require('./district/W1G.data.json'),
  W1H: require('./district/W1H.data.json'),
  W1J: require('./district/W1J.data.json'),
  W1K: require('./district/W1K.data.json'),
  W1S: require('./district/W1S.data.json'),
  W1T: require('./district/W1T.data.json'),
  W1U: require('./district/W1U.data.json'),
  W1W: require('./district/W1W.data.json'),
  W2: require('./district/W2.data.json'),
  W3: require('./district/W3.data.json'),
  W4: require('./district/W4.data.json'),
  W5: require('./district/W5.data.json'),
  W6: require('./district/W6.data.json'),
  W8: require('./district/W8.data.json'),
  W9: require('./district/W9.data.json'),
  WA1: require('./district/WA1.data.json'),
  WA10: require('./district/WA10.data.json'),
  WA11: require('./district/WA11.data.json'),
  WA12: require('./district/WA12.data.json'),
  WA13: require('./district/WA13.data.json'),
  WA14: require('./district/WA14.data.json'),
  WA15: require('./district/WA15.data.json'),
  WA16: require('./district/WA16.data.json'),
  WA2: require('./district/WA2.data.json'),
  WA3: require('./district/WA3.data.json'),
  WA4: require('./district/WA4.data.json'),
  WA5: require('./district/WA5.data.json'),
  WA6: require('./district/WA6.data.json'),
  WA7: require('./district/WA7.data.json'),
  WA8: require('./district/WA8.data.json'),
  WA9: require('./district/WA9.data.json'),
  WC1A: require('./district/WC1A.data.json'),
  WC1B: require('./district/WC1B.data.json'),
  WC1E: require('./district/WC1E.data.json'),
  WC1H: require('./district/WC1H.data.json'),
  WC1N: require('./district/WC1N.data.json'),
  WC1R: require('./district/WC1R.data.json'),
  WC1V: require('./district/WC1V.data.json'),
  WC1X: require('./district/WC1X.data.json'),
  WC2A: require('./district/WC2A.data.json'),
  WC2B: require('./district/WC2B.data.json'),
  WC2E: require('./district/WC2E.data.json'),
  WC2H: require('./district/WC2H.data.json'),
  WC2N: require('./district/WC2N.data.json'),
  WC2R: require('./district/WC2R.data.json'),
  WD17: require('./district/WD17.data.json'),
  WD18: require('./district/WD18.data.json'),
  WD23: require('./district/WD23.data.json'),
  WD24: require('./district/WD24.data.json'),
  WD25: require('./district/WD25.data.json'),
  WD3: require('./district/WD3.data.json'),
  WD4: require('./district/WD4.data.json'),
  WD6: require('./district/WD6.data.json'),
  WD7: require('./district/WD7.data.json'),
  WF1: require('./district/WF1.data.json'),
  WF10: require('./district/WF10.data.json'),
  WF11: require('./district/WF11.data.json'),
  WF12: require('./district/WF12.data.json'),
  WF13: require('./district/WF13.data.json'),
  WF15: require('./district/WF15.data.json'),
  WF16: require('./district/WF16.data.json'),
  WF17: require('./district/WF17.data.json'),
  WF2: require('./district/WF2.data.json'),
  WF3: require('./district/WF3.data.json'),
  WF6: require('./district/WF6.data.json'),
  WF8: require('./district/WF8.data.json'),
  WF9: require('./district/WF9.data.json'),
  WN1: require('./district/WN1.data.json'),
  WN2: require('./district/WN2.data.json'),
  WN5: require('./district/WN5.data.json'),
  WN6: require('./district/WN6.data.json'),
  WN7: require('./district/WN7.data.json'),
  WN8: require('./district/WN8.data.json'),
  WR1: require('./district/WR1.data.json'),
  WR10: require('./district/WR10.data.json'),
  WR11: require('./district/WR11.data.json'),
  WR12: require('./district/WR12.data.json'),
  WR13: require('./district/WR13.data.json'),
  WR14: require('./district/WR14.data.json'),
  WR15: require('./district/WR15.data.json'),
  WR2: require('./district/WR2.data.json'),
  WR3: require('./district/WR3.data.json'),
  WR4: require('./district/WR4.data.json'),
  WR5: require('./district/WR5.data.json'),
  WR6: require('./district/WR6.data.json'),
  WR9: require('./district/WR9.data.json'),
  WS1: require('./district/WS1.data.json'),
  WS10: require('./district/WS10.data.json'),
  WS11: require('./district/WS11.data.json'),
  WS12: require('./district/WS12.data.json'),
  WS13: require('./district/WS13.data.json'),
  WS14: require('./district/WS14.data.json'),
  WS15: require('./district/WS15.data.json'),
  WS2: require('./district/WS2.data.json'),
  WS3: require('./district/WS3.data.json'),
  WS5: require('./district/WS5.data.json'),
  WS7: require('./district/WS7.data.json'),
  WS9: require('./district/WS9.data.json'),
  WV1: require('./district/WV1.data.json'),
  WV10: require('./district/WV10.data.json'),
  WV11: require('./district/WV11.data.json'),
  WV13: require('./district/WV13.data.json'),
  WV14: require('./district/WV14.data.json'),
  WV15: require('./district/WV15.data.json'),
  WV16: require('./district/WV16.data.json'),
  WV2: require('./district/WV2.data.json'),
  WV4: require('./district/WV4.data.json'),
  WV5: require('./district/WV5.data.json'),
  WV6: require('./district/WV6.data.json'),
  WV9: require('./district/WV9.data.json'),
  YO1: require('./district/YO1.data.json'),
  YO10: require('./district/YO10.data.json'),
  YO11: require('./district/YO11.data.json'),
  YO12: require('./district/YO12.data.json'),
  YO17: require('./district/YO17.data.json'),
  YO19: require('./district/YO19.data.json'),
  YO21: require('./district/YO21.data.json'),
  YO22: require('./district/YO22.data.json'),
  YO23: require('./district/YO23.data.json'),
  YO24: require('./district/YO24.data.json'),
  YO25: require('./district/YO25.data.json'),
  YO26: require('./district/YO26.data.json'),
  YO30: require('./district/YO30.data.json'),
  YO31: require('./district/YO31.data.json'),
  YO32: require('./district/YO32.data.json'),
  YO41: require('./district/YO41.data.json'),
  YO42: require('./district/YO42.data.json'),
  YO51: require('./district/YO51.data.json'),
  YO60: require('./district/YO60.data.json'),
  YO61: require('./district/YO61.data.json'),
  YO62: require('./district/YO62.data.json'),
  YO7: require('./district/YO7.data.json'),
  YO8: require('./district/YO8.data.json'),
  YO90: require('./district/YO90.data.json'),
  ZE1: require('./district/ZE1.data.json'),
  ZE2: require('./district/ZE2.data.json')
}
